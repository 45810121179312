import API from '../services/api.service'
import router from '../router'

const user = JSON.parse(localStorage.getItem('user') || '{}')
const token = localStorage.getItem('x-access-token')

const auth = {
    namespaced: true,
    
    state: {
        user,
        token,
        otp: null, otpQR: ''
    },

    actions: {

        login({ commit }, user) {
            return API.login(user).then(res => {
                // console.log('login', res.data)
                commit('loginSuccess', res.data)
                return Promise.resolve(res.data)
            })
            .catch(error => {
                API.handleError(error)
                commit('logout')
                
                return Promise.reject(error)
            })
        },

        refreshToken({ commit }) {
            return API.refresh().then(res => {
                commit('setToken', res.data)
                return Promise.resolve(res.data)
            })
            .catch(error => {
                API.handleError(error)
                commit('setToken', null)
                router.push('/login')
                return Promise.reject(error)
            })
        },

        verifyOTP({ commit }, otp) {
            return API.verifyOTP({ token: otp })
            .then(res => {
                commit('loginSuccess', res.data)
            })
            .catch(err => {
                API.handleError(err)
                commit('logout')
            })
        },

        confirmGoogleAuthPairQR({ commit }) {
            return API.confirmGoogleAuthPairQR({
                otp: {
                    carrier: 'googleauthenticator', activated: true
                }
            })
            .then(res => {
                commit('logout')
                router.push('/login')
            })
            .catch(error => {
                API.handleError(error)
                commit('logout')
                router.push('/login')
            })
        },

        getUserOTP({ commit }, email) {
            return API.getUserOTP({ email })
            .then(res => {
                commit('setOTP', res.data)
            })
            .catch(error => {
                API.handleError(error)
            })
        },

        getGoogleAuthPairQR({ commit }) {
            return API.getGoogleAuthPairQR()
            .then(res => {
                commit('loginSuccess', res.data)
                commit('setOTPQR', res.data.qrcode)
            })
            .catch(error => API.handleError(error))
        },

    },

    mutations: {
        
        loginSuccess(state, data) {
            if(data.user){
                state.user = data.user
                localStorage.setItem('user', JSON.stringify(data.user))
            }
            
            state.token = data.token
            localStorage.setItem('x-access-token', state.token)
        },

        logout(state) {
            // console.log('auth.module/mutations - logout')
            state.user = null
            state.token = null
            localStorage.removeItem('x-access-token')
            localStorage.removeItem('user')
        },

        setToken(state, token) {
            if(token)localStorage.setItem('x-access-token', token)
            else localStorage.removeItem('x-access-token')
            state.token = token
        },

        setOTP(state, otp) { state.otp = otp },
        setOTPQR(state, otpQR) { state.otpQR = otpQR }
    },

    getters: {
        authorized: state => {
            if(state.user && state.token && state.token.length > 10)return true
            return false
        },
        
    }
}

export default auth