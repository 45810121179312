<template>
    <q-page>

        <!-- Transaction Table -->
        <q-table dense separator="horizontal" flat wrap-cells 
            virtual-scroll :rows-per-page-options="[0]" style="max-height: 100vh;"
            :columns="getTransColumns" :rows="getTransData" row-key="_id"
            v-model:pagination="pagination" v-model:fullscreen="tableFullScreen" selection="multiple"
            v-model:selected="selectedTrans" binary-state-sort class="no-padding">
            <template v-slot:top>
                <q-linear-progress v-if="linearProgress" indeterminate class="fixed-top" />
                <!-- Ledger Summary -->
                <q-toolbar>
                    <h4 class="q-ml-md">{{ lastAccount }}</h4>

                    <div class="q-ml-xl">
                        <q-item-section class="text-center">
                            <q-item-label class="text-h4" :class="clearedBalance > 0 ? 'text-positive' : 'text-negative'">
                                {{ clearedBalance }}
                            </q-item-label>
                            <q-item-label>Cleared Balance</q-item-label>
                        </q-item-section>
                    </div>

                    <h4 class="q-ml-lg">+</h4>

                    <div class="q-ml-lg">
                        <q-item-section class="text-center">
                            <q-item-label class="text-h4"
                                :class="unclearedBalance > 0 ? 'text-positive' : 'text-negative'">
                                {{ unclearedBalance }}
                            </q-item-label>
                            <q-item-label>Uncleared Balance</q-item-label>
                        </q-item-section>
                    </div>

                    <h4 class="q-ml-lg">=</h4>

                    <div class="q-ml-lg">
                        <q-item-section class="text-center">
                            <q-item-label class="text-h4" :class="workingBalance > 0 ? 'text-positive' : 'text-negative'">
                                {{ workingBalance }}
                            </q-item-label>
                            <q-item-label>Working Balance</q-item-label>
                        </q-item-section>
                    </div>

                    <q-space />
                    <div v-if="selectedTrans.length > 1" class="q-mr-lg">
                        <q-item-section class="text-center">
                            <q-item-label class="text-h4" :class="selectedTotal > 0 ? 'text-positive' : 'text-negative'">
                                {{ selectedTotal }}
                            </q-item-label>
                            <q-item-label>Selected Total({{ selectedTrans.length }})</q-item-label>
                        </q-item-section>
                    </div>
                    <!-- filter chips -->
                    <q-list dense :padding="false">
                        <q-item v-for="item in searchFilters" :key="item.field" dense>
                            <q-chip removable @remove="delSearchItem(item)">{{ item.field }} : {{ item.search }}</q-chip>
                        </q-item>
                        <q-item v-if="viewFilterOptions.length > 0">
                            <q-chip removable @remove="viewFilterOptions = []">
                                <q-icon v-for="item in viewFilterOptions" :key="item" class="q-mr-sm"
                                    :name="item === 'R' ? 'lock' : item === 'C' ? 'done' : 'chat_bubble'" />
                                <q-separator vertical />
                            </q-chip>
                        </q-item>
                    </q-list>

                    <div class="q-mr-lg">
                        <q-btn label="Reconcile" class="q-mb-sm">
                        </q-btn>
                        <q-menu>
                            <q-card v-if="!reconcilePrompt" class="bg-amber-1">
                                <q-card-section class="column items-center q-gutter-sm">
                                    <div>Is your <strong>current</strong> account balance</div>
                                    <div class="text-h4">
                                        <span class="text-h4"
                                            :class="currentBalance >= 0 ? 'text-positive' : 'text-warning'">{{
                                            currentBalance }}</span> ?
                                    </div>
                                </q-card-section>
                                <q-card-actions align="center">
                                    <q-btn label="Yes" color="primary" @click="reconcile(currentBalance)"
                                        v-close-popup />
                                    <q-btn label="no" color="primary" @click="reconcilePrompt = true" />
                                </q-card-actions>
                            </q-card>
                            <q-card v-else>
                                <q-card-section class="column items-center q-gutter-sm">
                                    <div class="text-h5">Enter your current account balance</div>
                                    <q-input v-model="reconcileAmount" />
                                </q-card-section>
                                <q-card-actions align="center">
                                    <q-btn label="Yes" color="primary" @click="reconcile(reconcileAmount)"
                                        v-close-popup />
                                </q-card-actions>
                            </q-card>
                        </q-menu>
                        <q-item-label class="text-italic" caption>Reconciled {{ lastReconciled ? lastReconciled :
                            'never' }}</q-item-label>
                    </div>


                </q-toolbar>
                <!-- Table toolbar -->
                <q-toolbar class="q-gutter-sm bg-info">
                    <q-btn label="Add Transaction" icon="add" flat @click="addNewTrans = true"></q-btn>
                    <q-btn label="File Import" icon="file_upload" flat></q-btn>
                    <q-btn v-if="selectedTrans.length < 1" label="Edit" icon="edit" flat></q-btn>
                    <q-btn-dropdown v-else :label="`Edit(${this.selectedTrans.length})`" icon="edit" flat>
                        <q-list>
                            <q-item clickable>
                                <q-btn label="Mark as Cleared" icon="done" @click="clearTransactions('C')" class=""
                                    flat />
                            </q-item>
                            <q-item clickable>
                                <q-btn label="Mark as Uncleared" icon="chat_bubble_outline"
                                    @click="clearTransactions('U')" class="" flat />
                            </q-item>
                            <q-separator />
                            <q-item clickable>
                                <q-btn label="Flag" icon="bookmark" flat />
                                <q-space />
                                <q-icon name='keyboard_arrow_right' size="md" />
                                <q-menu anchor="top right">
                                    <q-list>
                                        <q-item v-for="item in flagOptions" :key="item.label" :class="item.color"
                                            clickable @click="flagTransactions(item.label)">
                                            {{ item.label }}
                                        </q-item>
                                    </q-list>
                                </q-menu>
                            </q-item>
                            <q-item clickable>
                                <q-btn label="Change category..." icon="category" flat />
                                <q-space />
                                <q-icon name='keyboard_arrow_right' size="md" />
                                <q-menu anchor="top right">
                                    <q-input class="q-mx-sm" placeholder="category..." v-model="subCatSearch" />
                                    <q-list>
                                        <q-item clickable v-for="item in subCategories" :key="item._id"
                                            @click="changeCategory(item._id)" v-close-popup>
                                            {{ item.category }}
                                        </q-item>
                                    </q-list>
                                </q-menu>
                            </q-item>
                            <q-item clickable>
                                <q-btn label="Move to account..." icon="account_balance" flat />
                                <q-space />
                                <q-icon name='keyboard_arrow_right' size="md" />
                                <q-menu anchor="top right">
                                    <q-input class="q-mx-sm" placeholder="account..." v-model="accountSearch" />
                                    <q-list>
                                        <q-item clickable v-for="item in filteredAccounts" :key="item._id"
                                            @click="moveTo(item._id)" v-close-popup>
                                            {{ item.account }}
                                        </q-item>
                                    </q-list>
                                </q-menu>
                            </q-item>
                            <q-item clickable>
                                <q-btn label="Delete" icon="delete" @click="deleteTrans" class="" flat />
                            </q-item>

                        </q-list>
                    </q-btn-dropdown>

                    <q-btn label="Undo" icon="undo" flat></q-btn>
                    <q-btn label="Redo" icon="redo" flat></q-btn>

                    <q-space />
                    <q-chip v-if="filter" removable @remove="filter = null">{{ filter.field }} : {{ filter.search
                        }}</q-chip>
                    <q-chip v-if="timeframeFilter.length > 0" removable @remove="resetTimeFilter">{{ timeframeFilter
                        }}</q-chip>
                    <!-- Timeframe search -->
                    <q-btn label="View" :icon-right="showViewOptions ? 'arrow_drop_up' : 'arrow_drop_down'" flat>
                        <MenuTooltip v-model="showViewOptions">
                            <TimeframeFilter title="View Options" @dismiss="filterTimeRange"
                                @keyup.escape.prevent="filterTimeRange" :range="timeRangeFilter">
                                <q-option-group v-model="viewFilterOptions" :options="[
                                    { label: 'Show Uncleared', value: 'U' },
                                    { label: 'Show Cleared', value: 'C' },
                                    { label: 'Show Reconciled', value: 'R' },
                                ]" type="checkbox" />
                            </TimeframeFilter>
                        </MenuTooltip>
                    </q-btn>
                    <!-- Search Input -->
                    <q-input v-model="search" placeholder="Search" @keyup="searchKeyUp"
                        @focus="showSearchTooltip = true" @blur="showSearchTooltip = false"
                        @keyup.escape.prevent="showSearchTooltip = false" class="bg-white" standout="bg-teal" dense>
                        <template v-slot:prepend>
                            <q-icon name="search" />
                        </template>
                        <ToolTip v-model="showSearchTooltip">
                            <q-markup-table flat style="background:inherit; color:inherit">
                                <tr v-for="item in textSearchOptions" :key="item.value"
                                    :class="item.active && 'text-white bg-accent'">
                                    <td class="text-right text-italic" style="padding-right:0">{{ item.value ===
                                        'any'?'':`${item.label} :`}}
                                    </td>
                                    <td style="padding-left:1em"> Find "{{ lastSearchItem }}" in {{ item.label }}</td>
                                </tr>
                            </q-markup-table>
                        </ToolTip>
                    </q-input>
                    <q-btn :icon="tableFullScreen ? 'fullscreen_exit' : 'fullscreen'"
                        :color="tableFullScreen ? 'negative' : 'positive'" flat
                        @click="tableFullScreen = !tableFullScreen" />
                </q-toolbar>
            </template>

            <template v-slot:top-row>
                <TransactionEdit v-if="addNewTrans" ref="transEdit" :transData="newTransData"
                    @dismiss="addNewTrans = false" />
            </template>

            <template v-slot:body="props">
                <q-tr :props="props">
                    <q-td>
                        <q-checkbox v-model="props.selected"
                            @click="handleSelection($event, props.row, props.selected)" />
                    </q-td>
                    <q-td v-if="!lastAccountId">{{ props.row.from.account }}</q-td>
                    <q-td key="flag">
                        <q-icon name="bookmark" size="sm" :color="flagColor(props.row.flag)" />
                        <q-popup-edit v-model="props.row.flag" buttons v-slot="scope">
                            <q-list>
                                <q-item v-for="item in flagOptions" :key="item.label" :class="item.color" clickable
                                    @click="flagTrans(item.label, scope)">
                                    {{ item.label }}
                                </q-item>
                            </q-list>
                        </q-popup-edit>
                    </q-td>
                    <q-td key="date">
                        {{ formatDate(props.row.date) }}
                        <q-popup-edit v-model="props.row.date" v-slot="scope">
                            <q-date v-model="scope.value" mask="YYYY-MM-DD">
                                <div class="row items-center justify-end">
                                    <q-btn @click="updateField({ field: 'date', scope })" v-close-popup label="Close"
                                        color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-edit>
                    </q-td>
                    <q-td key="payee">
                        {{ props.row.transfer === true ? `Transfer : ${props.row.to.account}` : props.row.payeeId ?
                            props.row.payeeId.payee : '' }}
                        <q-popup-edit v-model="props.row.payeeId" v-slot="scope">
                            <q-input placeholder="payee" autofocus v-model="scope.value.payee"
                                @keyup.enter="updateField({ field: 'payeeId', scope })">
                                <q-tooltip no-parent-event v-model="showTooltip" class="shadow-6">
                                    <div style="max-height: 25rem; min-width:15rem">
                                        <q-list>
                                            <q-item v-for="item in filterPayees(scope.value)" :key="item._id">
                                                {{ item.payee }}
                                            </q-item>
                                        </q-list>
                                    </div>
                                </q-tooltip>
                            </q-input>
                        </q-popup-edit>
                    </q-td>
                    <q-td key="category">
                        {{ props.row.transfer === true ? '' : props.row.categoryId ?
                            findCategoryById(props.row.categoryId._id).label : '' }}
                        <q-popup-edit v-if="props.row.transfer" v-model="props.row.categoryId" v-slot="scope">
                            <q-input placeholder="category" autofocus v-model="scope.value.category"
                                @keyup.enter="updateField({ field: 'categoryId', scope })">
                                <q-tooltip class="shadow-6" no-parent-event v-model="showTooltip">
                                    <div style="max-height: 25rem; min-width:15rem">
                                        <div class="row">
                                            <h6 class="">Budget Categories</h6>
                                        </div>
                                        <hr />
                                        <q-list>
                                            <q-item v-for="item in filterCategories(scope.value.category)"
                                                :key="item.id">
                                                <q-item-section>
                                                    <q-item-label>{{ item.category }}</q-item-label>
                                                </q-item-section>
                                                <q-item-section v-if="item.balance" side>
                                                    <q-chip v-if="item.balance < 0" color="negative"
                                                        class="text-white">{{ item.balance }}</q-chip>
                                                    <q-item-label v-else>{{ item.balance }}</q-item-label>
                                                </q-item-section>
                                            </q-item>
                                        </q-list>
                                    </div>
                                </q-tooltip>
                            </q-input>
                        </q-popup-edit>
                    </q-td>
                    <q-td key="memo">
                        {{ props.row.memo }}
                        <q-popup-edit v-model="props.row.memo" v-slot="scope">
                            <q-input placeholder="memo" v-model="scope.value" autofocus
                                @keyup.enter="updateField({ field: 'memo', scope })" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="outflow" align="right">
                        {{ props.row.outflow === 0 ? '' : props.row.outflow }}
                        <q-popup-edit v-model="props.row.outflow" v-slot="scope">
                            <q-input placeholder="outflow" v-model="scope.value" autofocus
                                @keyup.enter="updateField({ field: 'outflow', scope })" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="inflow" align="right">
                        {{ props.row.inflow === 0 ? '' : props.row.inflow }}
                        <q-popup-edit v-model="props.row.inflow" v-slot="scope">
                            <q-input placeholder="inflow" v-model="scope.value" autofocus
                                @keyup.enter="updateField({ field: 'inflow', scope })" />
                        </q-popup-edit>
                    </q-td>
                    <q-td key="cleared">
                        <div class="row">
                            <q-icon size="sm" class="col text-left"
                                :name="props.row.cleared === 'R' ? 'lock' : props.row.cleared === 'C' ? 'done' : 'chat_bubble_outline'"
                                :color="props.row.cleared === 'U' ? 'negative' : 'positive'" />
                            <q-icon v-if="props.row.receipt" name="camera" size="sm" class="col q-px-sm" />
                        </div>
                    </q-td>
                </q-tr>
            </template>
        </q-table>

    </q-page>
</template>

<script>

import { mapGetters, mapState } from 'vuex'
import { date, Dialog, Notify } from 'quasar'
import API from '@/services/api.service'
import TransactionEdit from '@/components/TransactionEdit.vue'
import MenuTooltip from '@/components/MenuTooltip.vue'
import TimeframeFilter from '@/components/TimeframeFilter.vue'
import ToolTip from '@/components/ToolTip.vue'

export default {

    name: 'LedgerPage',

    components: {
        TransactionEdit,
        MenuTooltip,
        TimeframeFilter,
        ToolTip
    },

    data() {
        return {
            pagination: {
                rowsPerPage: 0
            }, tableFullScreen: false,
            selectedTrans: [],
            filter: null, search: '', viewFilterOptions: [], showSearchTooltip: false,
            timeframeFilter: '', timeRangeFilter: null,
            payeeSearch: '', catSearch: '', subCatSearch: '', accountSearch: '',
            addNewTrans: false,
            newTransData: {
                account: this.lastAccount, from: this.lastAccountId,
                date: new Date().toISOString().split('T')[0], payee: '', category: '', memo: '', inflow: '', outflow: '', cleared: 'U', flag: ''
            },
            showTooltip: true, showViewOptions: false,
            textSearchOptions: [
                { label: 'any field', value: 'any' },
                { label: 'Payee', value: 'payee' },
                { label: 'Category', value: 'category' },
                { label: 'Memo', value: 'memo' },
            ],
            searchFilters: [],
            flagOptions: [
                { label: '', color: 'bg-grey' },
                { label: 'Red', color: 'bg-red' },
                { label: 'Yellow', color: 'bg-yellow' },
                { label: 'Green', color: 'bg-green' },
                { label: 'Blue', color: 'bg-blue' },
                { label: 'Purple', color: 'bg-purple' },
                { label: 'Orange', color: 'bg-orange' },
            ],
            reconcilePrompt: false, reconcileAmount: 0,
            alwaysTrue: true,

        }
    },

    computed: {
        ...mapState({
            transactions: state => state.db.transactions,
            accounts: state => state.db.accounts,
            categories: state => state.db.categories,
            payees: state => state.db.payees,
            linearProgress: state => state.app.linearProgress,
            lastAccountId: state => state.app.lastAccountId,
        }),

        ...mapGetters({
            lastAccount: 'app/lastAccount',
            clearedBalance: 'db/clearedBalance',
            unclearedBalance: 'db/unclearedBalance',
            getPayees: 'db/getPayees',
        }),

        workingBalance() {
            return this.clearedBalance + this.unclearedBalance
        },

        selectedTotal() {
            return this.selectedTrans.reduce((total, item) => {
                return total + item.inflow - item.outflow
            }, 0)
        },

        selectedIds() {
            return this.selectedTrans.map(trans => { return trans._id })
        },

        getTransColumns() {
            let cols = [
                { label: 'Flag', field: 'flag', name: 'flag' },
                {
                    label: 'Date', field: 'date', align: 'left', format: val => {
                        let dateVal = new Date(val)
                        // return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
                        return date.formatDate(val, 'DD/MM/YYYY')
                    }
                },
                { label: 'Payee', field: 'payee', align: 'left' },
                { label: 'Category', field: 'category', align: 'left' },
                { label: 'Memo', field: 'memo', align: 'left' },
                { label: 'Outflow', field: 'outflow', align: 'right', format: val => { return val === 0 ? '' : val } },
                { label: 'Inflow', field: 'inflow', align: 'right', format: val => { return val === 0 ? '' : val } },
                { label: 'Clear', field: 'cleared', align: 'right', name: 'cleared' }
            ]
            if(!this.lastAccountId)cols.unshift({
                label: 'Account', field: 'account', align: 'left'
            })
            return cols
        },

        // filtering function
        getTransData() {
            if (this.searchFilters.length === 0 && this.viewFilterOptions.length === 0) return this.transactions
            let result = this.transactions.filter(item => {
                // let category = !item.category ? '':item.category.toLowerCase()
                let category = item.categoryId ? item.categoryId.category.toLowerCase() : '';
                let payee = !item.payee ? '' : item.payee.toLowerCase()
                let memo = !item.memo ? '' : item.memo.toLowerCase()
                let search = true
                this.searchFilters.map(filter => {
                    switch (filter.field) {
                        case 'payee': search = search && payee.includes(filter.search.toLowerCase()); break;
                        case 'category': search = search && category.includes(filter.search.toLowerCase()); break;
                        case 'memo': search = search && memo.includes(filter.search.toLowerCase()); break;
                        case 'any': {
                            search = false || payee.includes(filter.search.toLowerCase())
                                || category.includes(filter.search.toLowerCase())
                                || memo.includes(filter.search.toLowerCase())
                            break
                        }
                    }
                })

                let cleared = this.viewFilterOptions.length === 0
                this.viewFilterOptions.map(filter => {
                    cleared = cleared || item.cleared === filter
                })
                return search && cleared
            })
            return result
        },

        searchItems() {
            if (this.search.includes('|')) return this.search.split('|')
            if (this.search.includes('&')) return this.search.split('&')
            return [this.search]
        },

        lastSearchItem() {
            return this.searchItems[this.searchItems.length - 1]
        },

        subCategories() {
            let subs = []
            this.categories.map(master => {
                master.subs.map(sub => {
                    subs.push({
                        _id: sub._id, category: sub.category
                    })
                })
            })
            return subs.filter(item => item.category.toLowerCase().includes(this.subCatSearch.toLowerCase()))
        },

        filteredAccounts() {
            return this.accounts.filter(item => item.account.toLowerCase().includes(this.accountSearch.toLowerCase()))
        },

        // Reconcile
        lastReconciled() {
            let current = this.accounts.find(item => item._id === this.lastAccountId)
            if (current && current.lastReconciled) return this.formatDate(current.lastReconciled)
            return ''
        },
        currentBalance() {
            let current = this.accounts.find(item => item._id === this.lastAccountId)
            return current ? current.balance : 0
        },
        unclearedTrans() { return this.transactions.filter(item => item.cleared === 'U') },

    },

    methods: {
        deleteTrans() {
            this.selectedTrans.map(trans => {
                this.$store.dispatch('db/delTransaction', trans._id)
            })
            this.selectedTrans = []
        },

        formatDate(iso) {
            return date.formatDate(new Date(iso), 'DD-MM-YYYY HH:mm')
        },

        flagColor(color) {
            switch (color) {
                case 'Red': return 'red';
                case 'Yellow': return 'yellow';
                case 'Green': return 'green';
                case 'Purple': return 'purple';
                case 'Blue': return 'blue';
            }
            return 'grey'
        },

        clearTransactions(clear) {
            this.$store.dispatch('db/clearTransactions', { ids: this.selectedIds, clear })
            this.selectedTrans = []
        },

        flagTransactions(flag) {
            this.$store.dispatch('db/flagTransactions', { ids: this.selectedIds, flag })
            this.selectedTrans = []
        },

        flagTrans(flag, scope) {
            this.flagTransactions(flag)
            scope.value = flag
            scope.set()
        },

        changeCategory(categoryId) {
            this.subCatSearch = ''
            let data = this.selectedIds.map(item => {
                return {
                    _id: item, categoryId
                }
            })
            this.$store.dispatch('db/setCategory', data)
            this.selectedTrans = []
        },

        moveTo(accountId) {
            this.accountSearch = ''
            let data = this.selectedIds.map(item => {
                return {
                    _id: item, from: accountId
                }
            })
            this.$store.dispatch('db/moveTo', data)
            this.selectedTrans = []
        },

        updateField(data) {
            let record = {}

            switch (data.field) {
                case 'payeeId': {
                    let payees = this.filterPayees(data.scope.value)
                    if (payees.length > 0) {
                        let payee = payees[0]
                        data.scope.value = payee
                    }
                    else {
                        data.scope.value = { payee: data.scope.value.payee.trim() }
                    }
                    record[data.field] = data.scope.value._id
                    break
                }

                case 'categoryId': {
                    let category = this.filterCategories(data.scope.value.category)
                    if (category.length > 0) {
                        let first = category.find(item => { return item.master !== undefined })
                        if (first) {
                            data.scope.value.label = `${first.master} : ${first.category}`
                            data.scope.value._id = first.id
                            record[data.field] = data.scope.value._id
                        }
                    }
                    else return
                    break
                }

                default: {
                    record[data.field] = data.scope.value
                }
            }

            let id = this.selectedIds[0]
            console.log('updateField', id, record)
            this.$store.dispatch('db/updateTransaction', { id, record })
            data.scope.set()
        },

        handleSelection(event, row, selected) {
            // console.log(event, row)
            const { ctrlKey, shiftKey } = event
            if (shiftKey === true) {
                const currIndex = this.selectedTrans.findIndex(item => item._id == row._id)
                // console.log(currIndex, shiftKey)
                if (currIndex > 0) {
                    const prevTrans = this.selectedTrans[currIndex - 1]
                    if (!prevTrans) return
                    const prevTransIndex = this.getTransData.findIndex(item => item._id == prevTrans._id)
                    const currTransIndex = this.getTransData.findIndex(item => item._id == row._id)
                    const rangeRows = this.getTransData.slice(prevTransIndex, currTransIndex + 1)
                    for (const rangeRow of rangeRows) {
                        if (!this.selectedTrans.find(item => item._id == rangeRow._id)) this.selectedTrans.push(rangeRow)
                    }
                }
            }
        },

        filterPayees(payee) {
            let filter = payee.payee || ''
            return this.getPayees(this.lastAccount).filter(item =>
                item.payee.toLowerCase().includes(filter.toLowerCase())).slice(0, 10)
        },

        filterCategories(category) {
            let result = []
            this.categories.map(mast => {
                result.push({
                    category: mast.category,
                    id: mast._id
                })
                mast.subs.map(sub => {
                    result.push({
                        category: `${sub.category}`,
                        id: sub._id,
                        balance: sub.balance,
                        master: mast.category
                    })
                })
            })
            if (!category) return result
            return result.filter(item => item.category.toLowerCase().includes(category.toLowerCase())).slice(0, 10)
        },

        findCategoryById(id) {
            let result = {}
            this.categories.map(mast => {
                let match = mast.subs.find(sub => sub._id === id)
                if (match) {
                    result = {
                        ...match, label: `${mast.category} : ${match.category}`
                    }
                }
            })
            return result
        },

        filterTimeRange(range) {
            if (!range) {
                this.showViewOptions = false
                return
            }
            let query = {
                date: { '$gte': range.from, '$lte': range.to }
            }
            if (this.lastAccountId) query.from = this.lastAccountId
            this.$store.dispatch('db/queryTransactions', query)
            this.timeframeFilter = `${this.formatDate(range.from)} - ${this.formatDate(range.to)}`
            this.timeRangeFilter = range
            this.showViewOptions = false
        },
        resetTimeFilter() {
            this.$store.dispatch('db/getTransactions', this.lastAccountId)
            this.timeframeFilter = ''
            this.timeRangeFilter = null
        },

        searchKeyUp(e) {
            switch (e.key) {
                case 'ArrowDown': {
                    let index = this.textSearchOptions.findIndex(item => item.active)
                    if (index > -1) {
                        delete this.textSearchOptions[index].active
                        if (index < this.textSearchOptions.length - 1) this.textSearchOptions[index + 1].active = true
                        else this.textSearchOptions[0].active = true
                    }
                    else this.textSearchOptions[0].active = true
                    break
                }
                case 'ArrowUp': {
                    let index = this.textSearchOptions.findIndex(item => item.active)
                    if (index > -1) {
                        delete this.textSearchOptions[index].active
                        if (index > 0) this.textSearchOptions[index - 1].active = true
                        else this.textSearchOptions[this.textSearchOptions.length - 1].active = true
                    }
                    else this.textSearchOptions[this.textSearchOptions.length - 1].active = true
                    break
                }
                case 'Enter': {
                    if (this.search.length === 0) {
                        // this.showSearchTooltip = false
                        break
                    }
                    let active = this.textSearchOptions.find(item => item.active)
                    if (!active) active = this.textSearchOptions[0]
                    this.searchFilters.push({ field: active.value, search: this.search })
                    this.search = ''
                    break
                }
            }
        },
        delSearchItem(item) {
            this.searchFilters = this.searchFilters.filter(filter => filter !== item)
        },

        // Reconcile Function
        reconcile(amount) {
            if (this.unclearedTrans.length > 0) {
                // this.$store.dispatch('app/dialog', {
                //     title:'There are uncleared transactions', content:'Please review these transactions!', type:'alert',
                //     color:'warning'
                // })
                Dialog.create({
                    title: 'Uncleared Transactions!',
                    message: 'There are uncleared transactions. Please review before reconciling!'
                }).onOk(() => {
                    this.viewFilterOptions = ['U']
                })
                // console.log(this.$q)

            }
            else {
                if (this.currentBalance !== amount) {
                    // create reconcile transaction
                    let payeeId = this.payees.find(item => item.payee === 'Manual Balance Adjustment')._id
                    let master = this.categories.find(item => item.category === 'Income')
                    let categoryId = master.subs.find(item => item.category === 'Ready to Assign')._id
                    if (!payeeId || !categoryId) {
                        alert('no payeeId or categoryId')
                        return
                    }
                    let diff = this.currentBalance - amount
                    let newTransaction = {
                        date: new Date().toISOString(),
                        from: this.lastAccountId,
                        payeeId, payee: 'Manual Balance Adjustment',
                        categoryId, category: 'Ready to Assign',
                        memo: 'reconcile account',
                        inflow: diff < 0 ? diff : 0,
                        outflow: diff > 0 ? diff : 0,
                        flag: '', cleared: 'R'
                    }
                    this.newTransData = newTransaction
                    this.addNewTrans = true
                    this.reconcilePrompt = false
                }
                else {
                    // send reconcile to API / db.store
                    API.reconcile({
                        from: this.lastAccountId, amount
                    }).then(res => {
                        // console.log(res.data)
                        this.$q.notify({ message: 'Transactions reconciled', icon: 'done' })
                        // this.$store.dispatch('db/getTransactions', this.lastAccountId)
                        this.$store.commit('db/updateTransactions', res.data)
                    }).catch(err => {
                        this.$q.notify({ message: 'There was an error when reconciling transactions', icon: 'warning' })
                    })
                }

            }
        }
    },

    mounted() {
        //   console.log('ledger mounted')
        if (this.timeRangeFilter) {
            let query = {
                date: { '$gte': this.timeRangeFilter.from, '$lte': this.timeRangeFilter.to }
            }
            if (this.lastAccountId && this.lastAccountId !== '*') query.from = this.lastAccountId
            this.$store.dispatch('db/queryTransactions', query)
        }
        else this.$store.dispatch('db/getTransactions', this.lastAccountId)
    },
    watch: {
        '$route'() {
            if (this.timeRangeFilter) {
                let query = {
                    date: { '$gte': this.timeRangeFilter.from, '$lte': this.timeRangeFilter.to }
                }
                if (this.lastAccountId && this.lastAccountId !== '*') query.from = this.lastAccountId
                this.$store.dispatch('db/queryTransactions', query)
            }
            else this.$store.dispatch('db/getTransactions', this.lastAccountId)
        }
    }
}
</script>

<style scoped>
/* .q-td {font-size: 1rem;} */
/* .q-th {font-size: 1.2rem} */
</style>