import axios from "axios"
import Notify from 'quasar/src/plugins/Notify.js';
import store from '../store'

const API_URL = process.env.VUE_APP_API

const headers = () => {
    let accessToken = localStorage.getItem('x-access-token')
    return {'x-access-token':accessToken}
}

class API {

    // Authentication API
    login(user) {
        return axios.post(`${API_URL}/auth/login`, user)
    }

    logout(){
        localStorage.removeItem('x-access-token')
    }

    refresh(){
        return axios.get(`${API_URL}/auth/token`, {headers:headers()})
    }

    signup(user){
        return axios.post(`${API_URL}/user/`, user)
    }

    getTempToken(){
        return axios.post(`${API_URL}/user/token/temp`, {}, {headers:headers()})
    }
    getPermanentToken(data){
        return axios.post(`${API_URL}/user/token/permanent`, data, {headers:headers()})
    }
    revokeUserToken(data){
        return axios.post(`${API_URL}/user/token/revoke`, data, {headers:headers()})
    }
    getUserTokens(){
        return axios.get(`${API_URL}/user/tokens`, {headers:headers()})
    }

    getGoogleAuthPairQR(){
        return axios.get(`${API_URL}/auth/otp/pair`, { headers: headers() })
    }
    confirmGoogleAuthPairQR(data){
        return axios.post(`${API_URL}/auth/otp`, data, { headers: headers() })
    }
    getUserOTP(data){
        return axios.post(`${API_URL}/user/otp`, data)
    }

    verifyOTP(data){
        return axios.post(`${API_URL}/user/otp/verify`, data, { headers: headers() })
    }

    getRates() {
        return axios.get(`${API_URL}/tools/currency`, { headers: headers() })
    }

    // Registry API
    getTransactions(data) {
        return axios.post(`${API_URL}/registry/getRecords`, data, {headers:headers()})
    }

    searchTransactions(data){
        return axios.post(`${API_URL}/registry/search`, data, {headers:headers()})
    }

    addTransaction(data) {
        return axios.post(`${API_URL}/registry`, data, {headers:headers()})
    }

    updateTransaction(data){
        return axios.put(`${API_URL}/registry/${data.id}/simple`, data.record, {headers:headers()})
    }

    delTransaction(id) {
        return axios.delete(`${API_URL}/registry/${id}`, {headers:headers()})
    }

    clearTransactions(data) {
        return axios.put(`${API_URL}/registry/clear/transactions`, data, {headers:headers()})
    }

    flagTransactions(data) {
        return axios.put(`${API_URL}/registry/flag/transactions`, data, {headers:headers()})
    }

    setCategory(data){
        return axios.post(`${API_URL}/registry/setcategory`, data, {headers:headers()})
    }

    moveTo(data){
        return axios.post(`${API_URL}/registry/moveto`, data, {headers:headers()})
    }

    reconcile(data){
        return axios.post(`${API_URL}/registry/reconcile`, data, {headers:headers()})
    }


    // Budget API
    getCurrentBudget(){
        let date = new Date()
        return axios.get(`${API_URL}/categories/budget?month=${date.getMonth()}&year=${date.getFullYear()}`, {headers:headers()})
    }

    getBudget(data){
        let date = new Date(data)
        return axios.get(`${API_URL}/categories/budget?month=${date.getMonth()}&year=${date.getFullYear()}`, {headers:headers()})
    }

    addBudget(data){
        return axios.post(`${API_URL}/budget`, data, {headers:headers()})
    }

    updateBudget(data){
        return axios.put(`${API_URL}/budget/${data.id}`, {budgeted:data.budgeted}, {headers:headers()})
    }

    moveBudget(data){
        return axios.post(`${API_URL}/budget/move`, data, {headers:headers()})
    }

    deleteBudget(id){
        return axios.delete(`${API_URL}/budget/${id}`, {headers:headers()})
    }

    // Account API
    getAccounts(query){
        return axios.post(`${API_URL}/accounts/get`, query, {headers:headers()})
        // .then(res => {
        //     console.log('axios', res.data)
        //     return Promise.resolve(res)
        // }).catch(err => Promise.reject(err))
    }

    addAccount(data){
        return axios.post(`${API_URL}/accounts`, data, { headers: headers() })
    }

    editAccount(accountId, data){
        return axios.put(`${API_URL}/accounts/${accountId}`, data, { headers: headers() })
    }

    deleteAccount(accountId) {
        return axios.delete(`${API_URL}/accounts/${accountId}`, { headers: headers() })
    }


    // Category API
    getCategories(){
        return axios.get(`${API_URL}/categories`, {headers:headers()})
    }

    addCategory(data){
        return axios.post(`${API_URL}/categories`, data, { headers: headers() })
    }

    updateCategory(id, data){
        return axios.put(`${API_URL}/categories/${id}`, data, { headers: headers() })
    }

    // Payee API
    getPayees(){
        return axios.get(`${API_URL}/payees`, {headers:headers()})
    }

    getPayeeList() { return axios.get(`${API_URL}/payees/list`, { headers: headers() }) }
    getPayee(payeeId) { return axios.get(`${API_URL}/payees/${payeeId}`, { headers: headers() }) }
    updatePayee(payeeId, data) { return axios.put(`${API_URL}/payees/${payeeId}`, data, { headers: headers() }) }
    updatePayees(data) { return axios.put(`${API_URL}/payees`, data, { headers: headers() }) }
    mergePayees(data) { return axios.post(`${API_URL}/payees/merge`, data, { headers: headers() }) }
    deletePayee(payeeId, moveTo) { return axios.delete(`${API_URL}/payees/${payeeId}?moveTo=${moveTo}`, { headers: headers() }) }
    deletePayees(data, moveTo) { return axios.delete(`${API_URL}/payees?moveTo=${moveTo}`, data, { headers: headers() }) }


    // User API


    // Error handle
    handleError(error){
        console.log('handle error', error)
        let type = error.response.status < 500 ? 'warning':'negative'
        let message = error.response.status === 500 ? error.response.data.message : error.response.data.message
        Notify.create({
            type,
            message,
            closeBtn: true, timeout:10000
        })
        store.commit('app/setLinearProgress', false)
    }

    apiURL() { return API_URL }


}

export default new API()