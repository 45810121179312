<template>
  <q-page>
    <div class="no-scroll full-height column">
      <!-- Header -->
      <q-toolbar class="col-auto">

        <!-- Month Select -->
        <q-item dense>
          <q-item-section avatar>
            <q-btn round color="primary" icon="chevron_left" outline @click="changeMonth(-1)" />
          </q-item-section>
          <q-item-section>
            <q-btn-dropdown ref="calendar" :label="monthLabel" flat class="q-mb-sm">
              <q-date v-model="month" @update:model-value="checkMonthSelect" emit-immediately default-view="Months"
                ref="monthPicker">
                <div class="row items-center justify-end q-gutter-sm">
                  <q-btn label="Cancel" color="primary" flat v-close-popup />
                  <q-btn label="Ok" color="primary" outlined v-close-popup />
                </div>

              </q-date>
            </q-btn-dropdown>
            <q-item-label caption @click="showDateNote = true" class="q-px-auto">
              Enter a note...
              <q-menu class="q-pa-md">
                <q-input placeholder="Enter a note..." type="textarea" autofocus v-model="dateNote" />
                <q-btn label="Ok" @click="setNote" color="primary" v-close-popup class="float-right q-mt-md" />
              </q-menu>

            </q-item-label>
          </q-item-section>
          <q-item-section avatar>
            <q-btn round color="primary" icon="chevron_right" outline @click="changeMonth(1)" />
          </q-item-section>
        </q-item>

        <q-btn v-show="!isCurrentMonth" label="Today" color="primary" @click="changeMonth(0)" />

        <!-- Assign Card -->
        <q-card class="q-ml-lg text-white row" :class="assignDetails.class">
          <q-item>
            <q-section>
              <q-item-label class="text-h5">
                {{ assignDetails.total.toLocaleString('de-DE') }} Lek
              </q-item-label>
              <q-item-label caption class="text-white">{{ assignCardCaption }}</q-item-label>
            </q-section>
            <q-section avatar>
              <q-icon name="done" />
            </q-section>

            <q-menu>
              <q-card>
                <q-card-section>
                  <div class="text-h6">Ready to Assign Breakdown</div>
                </q-card-section>
                <q-card-section>
                  <q-list dense>

                    <q-item>
                      <q-item-section>
                        <q-icon name="add_circle" color="positive" />
                      </q-item-section>
                      <!-- <q-space/> -->
                      <q-item-section side>
                        + {{ assignDetails.inflowTotal.toLocaleString('de-DE') }} Lek
                      </q-item-section>
                    </q-item>

                    <q-item v-for="item in assignDetails.inflow" :key="item.label">
                      <q-item-section>
                        <q-item-label>{{ item.label }}</q-item-label>
                      </q-item-section>
                      <q-item-section side :class="item.amount < 0 ? 'text-negative' : ''">
                        {{ item.amount > 0 ? '+' : '' }} {{ item.amount.toLocaleString('de-DE') }} Lek
                      </q-item-section>
                    </q-item>

                    <q-separator />

                    <q-item class="bg-light-grey">
                      <q-item-section>
                        <q-icon name="do_not_disturb_on" color="grey" />
                      </q-item-section>
                      <!-- <q-space/> -->
                      <q-item-section side>
                        - {{ assignDetails.outflowTotal.toLocaleString('de-DE') }} Lek
                      </q-item-section>
                    </q-item>

                    <q-item v-for="item in assignDetails.outflow" :key="item.label">
                      <q-item-section>
                        <q-item-label>{{ item.label }}</q-item-label>
                      </q-item-section>
                      <q-item-section side>
                        {{ item.amount > 0 ? '-' : '' }} {{ item.amount.toLocaleString('de-DE') }} Lek
                      </q-item-section>
                    </q-item>

                    <q-separator />

                    <q-item>
                      <q-item-section>
                        <q-item-label class="text-h5">Total Ready to Assign</q-item-label>
                        <q-item-label caption>
                          Give these dollars a job by assigning them to one or more categories!
                        </q-item-label>
                      </q-item-section>
                      <q-item-section side :class="parseInt(assignDetails.total) > 0 ? 'text-green' : ''">
                        {{ assignDetails.total.toLocaleString('de-DE') }} Lek
                      </q-item-section>
                    </q-item>

                  </q-list>
                </q-card-section>
              </q-card>
            </q-menu>

          </q-item>

          <q-btn label="Assign" color="light-green" icon-right="keyboard_arrow_down">
            <q-menu>
              <div class="text-h5 q-pa-md">Coming soon...</div>
            </q-menu>
          </q-btn>
        </q-card>

        <q-space />
        <!-- <q-btn label="Refresh" outlined icon="refresh" color="positive" @click="setBudget" /> -->
        <q-item>
          <q-item-section>
            <q-item-label class="text-h6">{{ ageOfMoney.toFixed(0) }} Days</q-item-label>
          </q-item-section>
        </q-item>

      </q-toolbar>

      <!-- Body -->
      <div class="col row">

        <!-- Table -->
        <div class="col-8">
          <!-- Table toolbar -->
          <q-toolbar class="q-gutter-sm">
            <q-btn-dropdown label="Category Group" icon="add_circle" dropdown-icon="none" color="primary" flat>
              <q-item class="q-pa-md">
                <q-card flat>
                  <q-card-section>
                    <q-input v-model="newGroupCat" placeholder="New Category Group" outlined
                      @keyup.enter="addGroupCategory" />
                  </q-card-section>
                  <q-card-actions align="right">
                    <q-btn label="Cancel" v-close-popup />
                    <q-btn label="Ok" @click="addGroupCategory" v-close-popup />
                  </q-card-actions>
                </q-card>
              </q-item>
            </q-btn-dropdown>
            <q-btn label="Undo" icon="undo" color="primary" flat />
            <q-btn label="Redo" icon="redo" color="primary" flat />
            <q-btn label="Recent Moves" icon="watch_later" color="primary" flat />
            <q-space />
            <q-btn :icon="showProgress ? 'dns' : 'list'" color="primary" outline
              @click="showProgress = !showProgress" />
            <!-- <q-btn icon="list" color="primary" outline @click="showProgress = false"/> -->
          </q-toolbar>

          <!-- Table -->
          <q-table :columns="Columns" :rows="filteredBudget" row-key="_id" selction="multiple"
            v-model:selected="selectedItems" v-model:pagination="pagination" class="my-sticky-virtscroll-table hide-scrollbar"
            virtual-scroll :virtual-scroll-sticky-size-start="48">
            <template v-slot:body="props">

              <!-- Budget Master summary -->
              <q-tr :props="props" class="text-white bg-primary">
                <q-td>
                  {{ props.row._id }}
                  <q-popup-edit v-model="props.row._id" buttons v-slot="scope" @save="updateMasterCat">
                    <q-input v-model="scope.value" dense autofocus counter @keypress.enter="scope.set" />
                  </q-popup-edit>
                  <span>
                    <q-btn-dropdown dropdown-icon="add_circle" flat>
                      <q-item class="q-pa-md">
                        <q-card flat>
                          <q-card-section>
                            <q-input v-model="newSubCat" placeholder="New Category" outlined />
                          </q-card-section>
                          <q-card-actions align="right">
                            <q-btn label="Cancel" v-close-popup />
                            <q-btn label="Ok" @click="addSubCategory(props.row._id)" v-close-popup />
                          </q-card-actions>
                        </q-card>
                      </q-item>
                    </q-btn-dropdown>
                  </span>
                </q-td>
                <q-td auto-width align="right">
                  {{ props.row.assign }}
                </q-td>
                <q-td auto-width align="right" :class="props.row.trans.length > 0 && 'cursor-pointer'">
                  {{ parseInt(props.row.activity) }}
                  <q-menu v-if="props.row.trans.length > 0">
                    <TransactionPreview :data="props.row.trans" />
                  </q-menu>
                </q-td>
                <q-td auto-width align="right">{{ parseInt(props.row.inherit + props.row.assign + props.row.activity)
                  }}</q-td>
              </q-tr>

              <!-- Budget Subs -->
              <q-tr v-for="item in props.row.subs" :key="item._id">
                <q-td>
                  {{ item.category }}
                  <q-popup-edit v-model="item.category" buttons v-slot="scope" @save="updateSubCat">
                    <q-input v-model="scope.value" dense autofocus @keypress.enter="scope.set" />
                  </q-popup-edit>
                  <div v-if="showProgress">
                    <q-linear-progress :buffer="spentBuffer(item).buffer" size="sm" :value="spentBuffer(item).progress"
                    color="white" :track-color="spentBuffer(item).color" />
                    <q-linear-progress stripe size="lg" :value="spentProgress(item)"
                    :color="progressColor(item)" />
                  </div>
                </q-td>
                <q-td auto-width align="right">
                  <span>
                    {{ item.assign }}
                    <q-popup-edit v-model="item.assign" v-slot="scope" anchor="top end">
                      <q-input v-model="scope.value" :hint="item.category" autofocus
                        @keyup.enter="updateBudget(scope, item)" />
                      <q-list separator dense class="text-primary">
                        <q-item>
                          <q-item-section>Assigned Last Month</q-item-section>
                          <q-item-section side> {{ item.pastMonthAssign }}</q-item-section>
                        </q-item>
                        <q-item>
                          <q-item-section>Spent Last Month</q-item-section>
                          <q-item-section side> {{ item.lastMonthActivity }}</q-item-section>
                        </q-item>
                        <q-item>
                          <q-item-section>Average Assigned</q-item-section>
                          <q-item-section side> {{ item.averageAssign }}</q-item-section>
                        </q-item>
                        <q-item>
                          <q-item-section>Average Spent</q-item-section>
                          <q-item-section side> {{ item.averageActivity }}</q-item-section>
                        </q-item>
                      </q-list>
                    </q-popup-edit>
                  </span>
                  <q-icon name="bookmark" class="q-ml-sm pointer-cursor">
                    <q-menu v-if="item.moves.length > 0">
                      <!-- Popup Table of moves -->
                      <q-table :rows="item.moves">
                        <!-- headings -->
                        <template v-slot:header="props">
                          <q-tr :props="props">
                            <q-th>Date</q-th>
                            <q-th>From</q-th>
                            <q-th>To</q-th>
                            <q-th>Amount</q-th>
                            <q-th></q-th>
                          </q-tr>
                        </template>
                        <!-- body -->
                        <template v-slot:body="props">
                          <q-tr :props="props">
                            <q-td>{{ formatDate(props.row.date) }}</q-td>
                            <q-td>{{ props.row.from }}</q-td>
                            <q-td>{{ props.row.to }}</q-td>
                            <q-td>{{ props.row.amount }}</q-td>
                            <q-td><q-icon name="delete" class="cursor-pointer"
                                @click="deleteBudgetMove(props.row._id)" /></q-td>
                          </q-tr>
                        </template>
                      </q-table>
                    </q-menu>
                  </q-icon>

                </q-td>
                <q-td auto-width align="right">
                  {{ parseInt(item.activity) }}
                  <q-menu v-if="item.trans.length > 0">
                    <TransactionPreview :data="item.trans" />
                  </q-menu>
                </q-td>
                <q-td auto-width align="right">
                  {{ parseInt(item.inherit + item.assign + item.activity) }}
                  <q-menu class="q-pa-md" ref="moveBudget">
                    <q-input label="Move:" v-model="move.amount" autofocus @focus="setMoveAmount(item)" />
                    <!-- <q-input label="To:" /> -->
                    <CategoryList @setCategory="moveBudget" v-close-popup />
                  </q-menu>
                </q-td>
              </q-tr>
            </template>
          </q-table>

        </div>

        <!-- Side Bar -->
        <div class="col-4 q-pa-lg">
          <div>
            <q-expansion-item expand-separator switch-toggle-side label="Available in" :caption="monthLabel"
              default-opened>
              <template v-slot:header>
                <q-item-section>
                  <q-item-label>Available in</q-item-label>
                  <q-item-label caption>{{ monthLabel }}</q-item-label>
                </q-item-section>
                <q-item-section side>{{ parseInt(statistics.available).toLocaleString('de-DE') }}</q-item-section>
              </template>
              <q-list bordered>
                <q-item v-for="item in availableNow" :key="item.label">
                  <q-item-section>{{ item.label }}</q-item-section>
                  <q-space />
                  <q-item-section side class="float-right">{{ parseInt(item.value).toLocaleString('de-DE')
                    }}</q-item-section>
                </q-item>
              </q-list>
            </q-expansion-item>
            <DoughnutChart v-if="budget.length > 0" :chartData="masterChartData.data"
              :options="masterChartData.options" />
            <BarChart v-if="budget.length > 0" :chartData="subChartData.data" :options="subChartData.options" />
          </div>
        </div>
      </div>

      <q-linear-progress v-if="linearProgress" indeterminate class="fixed-top" />
    </div>
  </q-page>
</template>

<script>
import { date } from 'quasar'
import { mapGetters, mapState } from 'vuex'
import TransactionPreview from '@/components/TransactionPreview.vue'
import ChartData from '../utilities/chart'

import { DoughnutChart, BarChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import CategoryList from '@/components/CategoryList.vue'
Chart.register(...registerables)

export default {
  name: "BudgetPage",
  data() {
    return {
      month: new Date().toISOString(),
      dateNote: "",
      selectedItems: [],
      showDateNote: false, showProgress: true,
      pagination: { rowsPerPage: 0 }, move: {},
      newGroupCat: '', newSubCat: ''
    }
  },
  computed: {
    ...mapState({
      budget: state => state.db.budget,
      categories: state => state.db.categories,
      linearProgress: state => state.app.linearProgress,
      accounts: state => state.db.accounts
    }),
    ...mapGetters({
      getBudget: "db/getBudget",
      allAccountsSum: "db/allAccountsSum"
    }),
    filteredBudget() {
      return this.budget.filter(item => item._id != 'Income')
    },
    monthLabel() { return date.formatDate(new Date(this.month), "MMM YYYY"); },
    isCurrentMonth() {
      let monthDate = new Date(this.month);
      let currDate = new Date();
      return (monthDate.getMonth() === currDate.getMonth() && monthDate.getFullYear() === currDate.getFullYear());
    },
    // Table
    Columns() {
      return [
        { label: "Category", field: "category", align: "left" },
        { label: "Assigned", field: "budgeted" },
        { label: "Activity", field: "spent" },
        { label: "Available", field: "balance" },
      ];
    },

    statistics() {
      let budget = this.budget.filter(item => item._id !== "Income")
      let inherit = budget.reduce((total, item) => { return total + item.inherit }, 0)
      let assign = budget.reduce((total, item) => { return total + item.assign }, 0)
      let activity = budget.reduce((total, item) => { return total + item.activity }, 0)
      let incomeBudget = this.budget.filter(item => item._id == 'Income')
      let income = incomeBudget
        .reduce((total, item) => { return total + item.activity }, 0)
      let leftUnassignedIncome = incomeBudget.reduce((total, item) => { return total + item.inherit }, 0)
      let prevMonth = date.addToDate(this.month, { months: -1 })
      return {
        inherit, assign, activity, income, leftUnassignedIncome,
        available: inherit + assign + activity + this.readyToAssign.amount,
        prevMonth
      }
    },

    availableNow() {
      return [
        { label: 'Ready to Assign', value: this.readyToAssign.amount },
        { label: 'Left Over from Last Month', value: this.statistics.inherit },
        { label: `Assigned in ${this.monthLabel}`, value: this.statistics.assign },
        { label: 'Activity', value: this.statistics.activity },
      ]
    },
    readyToAssign() {
      let result = { amount: 0, class: '', moves: [] }
      let income = this.budget.find(item => item._id === "Income")
      if (income) {
        result.amount = income.inherit + income.assign + income.activity
        if (result.amount < 0) result.class = 'bg-negative'
        else if (result.amount > 0) result.class = 'bg-positive'
        let assign = income.subs.find(item => item.category === "Ready to Assign")
        if (assign) result.moves = assign.moves
      }
      return result
    },
    assignCardCaption() {
      const amount = this.assignDetails.total
      return amount < 0 ? `Overbudgeted` : amount == 0 ? `All Money Assigned` : `Ready to Assign`
    },

    assignDetails() {
      const stats = this.statistics
      let result = {
        inflow: [
          // { label: `Ready to Assign left over from ${date.formatDate(stats.prevMonth, 'MMM')}`, amount: parseInt(stats.leftUnassignedIncome).toLocaleString('de-DE') },
          { label: `Inflow: Ready to Assign transactions in ${date.formatDate(this.month, 'MMM')}`, amount: parseInt(stats.income) },
        ],
        inflowTotal: parseInt(stats.income + stats.leftUnassignedIncome),
        outflow: [
          { label: `Assigned in ${date.formatDate(this.month, 'MMM')}`, amount: parseInt(stats.assign) },
          // { label: 'Assigned in Future', amount: '-67,500 Lek' }, // TODO
        ],
        outflowTotal: parseInt(stats.assign),
        total: parseInt(stats.income + stats.leftUnassignedIncome - stats.assign),
      }
      if(stats.leftUnassignedIncome > 0)result.inflow.push({
        label: `Ready to Assign left over from ${date.formatDate(stats.prevMonth, 'MMM')}`, 
        amount: parseInt(stats.leftUnassignedIncome)
      })
      else if(stats.leftUnassignedIncome < 0)result.outflow.push({
        label: `Overbudgeted last month`,
        amount: parseInt(stats.leftUnassignedIncome) * (-1)
      })
      if(stats.inherit < 0)result.outflow.push({
        label: `Overspent last month`,
        amount: parseInt(stats.inherit) * (-1)
      })
      result.inflowTotal = result.inflow.reduce((total, item) => { return total + item.amount }, 0)
      result.outflowTotal = result.outflow.reduce((total, item) => { return total + item.amount }, 0)
      result.total = result.inflowTotal - result.outflowTotal
      result.class = result.total < 0 ? 'bg-negative' : 'bg-positive'
      return result
    },
    ageOfMoney(){
      const total = this.accounts.reduce((tot, item) => { return tot + item.balance }, 0)
      const spenditure = Math.max(this.statistics.activity, this.statistics.assign)
      return Math.ceil(30 * total / spenditure)
    },

    masterChartData() {
      let data = this.budget.filter(item => item._id !== "Income").map(master => {
        let available = master.inherit + master.assign + master.activity
        return { label: master._id, value: master.assign }
      })
      let chart = ChartData.budgetChart('master', data)
      return { ...chart, options: ChartData.options('') }
    },
    subChartData() {
      let data = []
      this.budget.filter(item => item._id !== "Income").map(master => {
        master.subs.map(sub => {
          let available = sub.inherit + sub.assign + sub.activity
          data.push({ label: sub.category, value: available })
        })
      })
      let chart = ChartData.budgetChart('categories', data)
      return { ...chart, options: ChartData.options('') }
    }

  },
  methods: {
    checkMonthSelect(val, reason, details) {
      // console.log('checkMonthSelect', val, reason, details)
      if (reason === "month") {
        this.$refs.calendar.hide()
        this.$refs.monthPicker.setView("Months");
        this.setBudget();
      }
    },
    changeMonth(incr) {
      this.month = incr == 0 ? new Date() : date.addToDate(this.month, { months: incr });
      this.setBudget();
    },
    setNote() {
      this.showDateNote = false;
    },
    setBudget() {
      this.$store.dispatch("db/getBudget", this.month).then(res => {
        console.log("setBudget", res);
      }).catch(err => {
        console.log(err);
      });
    },
    setMoveAmount(item) {
      this.move = { _id: item._id, amount: item.inherit + item.assign + item.activity }
    },
    moveBudget(to) {
      // this.$refs.moveBudget.hide()
      // console.log(this.move._id, to._id, this.move.amount)
      this.$store.dispatch('db/moveBudget', { from: this.move._id, to: to._id, amount: this.move.amount, month: this.month })
    },
    updateBudget(scope, row) {
      console.log(scope, row);

      let id = row._id
      let assign = parseInt(scope.value) - scope.initialValue
      // console.log('set budgt to', assign)
      scope.set();
      if (assign === 0) return
      this.$store.commit('db/updateBudget', { id, assign, month: this.month })
      this.$store.dispatch('db/addBudget', { id, assign, month: this.month })
    },
    deleteBudgetMove(id) {
      this.$store.dispatch('db/deleteBudgetMove', id)
    },

    addGroupCategory() {
      if (this.newGroupCat == '' || this.categories.find(item => item.category == this.newGroupCat)) return
      let data = {
        category: this.newGroupCat,
        type: 'master'
      }
      this.$store.dispatch('db/addCategory', data)
        .then(res => {
          this.setBudget()
        })
        .finally(() => { this.newGroupCat = '' })
    },
    addSubCategory(master) {
      const masterCat = this.categories.find(item => item.category == master)
      console.log(master, masterCat)
      if (!masterCat || this.newSubCat == '' || this.categories.find(item => item.category == this.newSubCat)) return
      let data = {
        category: this.newSubCat,
        type: 'sub',
        master: masterCat._id
      }
      this.$store.dispatch('db/addCategory', data)
        .then(res => {
          this.setBudget()
        })
        .finally(() => { this.newSubCat = '' })
    },
    updateMasterCat(value, initialValue) {
      const category = this.categories.find(item => item.category == initialValue)
      if (!category) return
      let data = {
        _id: category._id,
        category: value
      }
      this.$store.dispatch('db/updateCategory', data)
        .then(res => this.$store.dispatch('db/getCategories'))
    },
    updateSubCat(value, initialValue) {
      let category
      for (const master of this.categories) {
        let match = master.subs.find(item => item.category == initialValue)
        if (match) {
          category = match
          break
        }
      }
      if (!category) return
      let data = {
        _id: category._id,
        category: value
      }
      this.$store.dispatch('db/updateCategory', data)
        .then(res => this.$store.dispatch('db/getCategories'))
    },

    spentProgress(row) {
      let fund = row.inherit + row.assign
      let available = fund + row.activity
      return (available == 0 && row.activity == 0) ? 0 :
        available < 0 ? 1 :
          Math.min(1, Math.abs(row.activity / fund))
    },
    spentBuffer(row) {
      const month = new Date(this.month)
      const daysInMonth = new Date(month.getFullYear(), month.getMonth(), 0).getDate()
      const today = new Date()
      const todayFirst = new Date().setDate(1)
      if(month < todayFirst)return { buffer: 0, progress: 0, color: 'white' }
      const buffer = today.getDate() / daysInMonth
      const spent = this.spentProgress(row)
      return {
        buffer: Math.max(buffer, spent),
        progress: Math.min(buffer, spent),
        color: buffer > spent ? 'secondary' : 'negative'
      }
    },
    progressColor(row) {
      let fund = row.inherit + row.assign
      let available = fund + row.activity
      let val = (available == 0 && row.activity == 0) ? 0 :
        available < 0 ? 1 :
          Math.min(1, Math.abs(row.activity / fund))
      if (val === 0) return 'grey'
      else if (val < 0.75) return 'positive'
      else if (val < 1) return 'warning'
      else if (available == 0) return 'primary'
      else return 'negative'
    },
    formatDate(value) { return date.formatDate(value, 'DD-MM-YYYY') },
  },
  mounted() {
    // set to current budget
    this.setBudget();
  },
  components: { TransactionPreview, DoughnutChart, BarChart, CategoryList }
}
</script>

<style lang="sass">
.my-sticky-virtscroll-table
  /* height or max-height is important */
  height: 90vh

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th /* bg color is important for th; just specify one */
    background-color: lightgrey

  thead tr th
    position: sticky
    z-index: 1
  /* this will be the loading indicator */
  thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
  thead tr:first-child th
    top: 0

  /* prevent scrolling behind sticky top row on focus */
  tbody
    /* height of all previous header rows */
    scroll-margin-top: 48px
</style>