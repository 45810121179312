<template>
  <q-page>

    <q-linear-progress v-if="linearProgress" indeterminate class="fixed-top" />

    <!-- Tab bar -->
    <q-tabs v-model="panel" align="left">
      <q-tab name="spending" label="Spending" />
      <q-tab name="networth" label="Net Worth" />
      <q-tab name="flow" label="Income v Expense" />
    </q-tabs>

    <!-- Filter Toolbar -->
    <q-toolbar class="q-gutter-sm">
      <!-- All Categories -->
      <q-btn-dropdown label="All Categories" flat>
        <CategorySelect v-model:selected="categoryFilter" @update:selected="getData" />
      </q-btn-dropdown>
      <!-- Time Filter -->
      <q-btn-dropdown ref="timeFilterBtn" :label="timeFilterLabel" flat>
        <TimeframeFilter v-model:range="timeFilter" @update:range="getData" @dismiss="$refs.timeFilterBtn.hide" />
      </q-btn-dropdown>
      <!-- All Accounts -->
      <q-btn-dropdown label="All Accounts" flat>
        <AccountSelect v-model:selected="accountFilter" @update:selected="getData" />
      </q-btn-dropdown>
      <q-space />
      <!-- Export Button -->
      <q-btn label="Export" flat />
    </q-toolbar>

    <!-- Main View (Panel / Details) -->
    <div class="row q-mr-xl">

      <div class="col-9 overflow-auto">
        <q-tab-panels v-model="panel">
          <!-- Spending Panel -->
          <q-tab-panel name="spending">
            <!-- toolbar -->
            <q-toolbar>
              <q-item-section @click="resetCatFilter">
                <q-item-label>Spending Totals</q-item-label>
                <q-item-label caption>All Categories</q-item-label>
              </q-item-section>
              <q-space />
              <q-tabs v-model="chartType">
                <q-tab name="pie" label="Pie" />
                <q-tab name="trends" label="Trends" />
              </q-tabs>
            </q-toolbar>
            <!-- Chart -->
            <div class="q-justify-center">
              <DoughnutChart v-if="chartType === 'pie'" class="" style="height:80vh" :chartData="masterExpenses.data"
                :options="masterExpenses.options" />
              <!-- <BarChart v-else :chartData="masterExpensesTrend.series" :options="masterExpensesTrend.options" :height="750" /> -->
              <apexchart v-else 
                type="bar" class="fit"
                :series="masterExpensesTrend.series" :options="masterExpensesTrend.options">
              </apexchart>
            </div>

          </q-tab-panel>

          <!-- Net Worth Panel -->
          <q-tab-panel name="networth">
            <div>
              <apexchart v-if="showChart" ref="netWorthChart" type="line" :series="chart.series"
                :options="chart.options" @dataPointSelection="networthClick"></apexchart>
            </div>
          </q-tab-panel>

          <!-- Income vs Expense Panel -->
          <q-tab-panel name="flow">
            Income vs Expense
          </q-tab-panel>
        </q-tab-panels>
      </div>

      <div class="col q-gutter-md">
        <q-table :rows="summaryData" :columns="summaryCol" :pagination="previewPagination" wrap-cells
          style="max-height: 50vh;" class="bg-teal-1 col-auto"></q-table>
        <q-card class="bg-orange-1 col-auto">
          <div class="text-h5 q-pa-lg">Monthly Statistics</div>
          <q-separator color="primary"/>
          <q-list>
            <q-item>
              <q-item-section>
                <q-item-label>Average</q-item-label>
              </q-item-section>
              <q-item-section side>{{ trendStats.avg.toLocaleString('de-DE') }}</q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label>Min</q-item-label>
              </q-item-section>
              <q-item-section side>{{ trendStats.min.toLocaleString('de-DE') }}</q-item-section>
            </q-item>
            <q-item>
              <q-item-section>
                <q-item-label>Max</q-item-label>
              </q-item-section>
              <q-item-section side>{{ trendStats.max.toLocaleString('de-DE') }}</q-item-section>
            </q-item>
          </q-list>
        </q-card>
        
      </div>
    </div>
    <q-table v-if="previewTrans.length > 0" :rows="previewTrans" :columns="previewTransCol" :pagination="previewPagination" wrap-cells
          title="Transactions Preview" style="max-height: 50vh;" class="bg-amber-2 q-mx-lg"></q-table>

    <q-linear-progress v-if="linearProgress" indeterminate class="fixed-top" />
  </q-page>
</template>

<script>
import { mapState } from 'vuex'
import { date } from 'quasar'
import CategorySelect from '../components/CategorySelect.vue';
import TimeframeFilter from '@/components/TimeframeFilter.vue';
import AccountSelect from '../components/AccountSelect.vue';
import ChartData from '../utilities/chart'
// import TransactionPreview from '@/components/TransactionPreview.vue';

import { DoughnutChart, BarChart } from 'vue-chart-3'
// import { Chart, registerables } from 'chart.js'
// Chart.register(...registerables)

export default {
  name: 'ReportPage',

  components: {
    CategorySelect,
    TimeframeFilter,
    AccountSelect,
    DoughnutChart, //BarChart,
    // VueAppexCharts,
    // TransactionPreview
  },

  data() {
    return {
      panel: 'spending', chartType: 'trend',
      categoryFilter: [],
      timeFilter: {
        from: date.formatDate(date.addToDate(Date.now(), { months: -5 }).setDate(1), 'YYYY-MM-DD'),
        to: date.formatDate(date.addToDate(Date.now(), { months: 1 }).setDate(1), 'YYYY-MM-DD'),
      },
      accountFilter: [],
      showTimeSelector: false, masterCatFilter: null, subCatFilter: null,
      noPagination: { rowsPerPage: 0 },

      options: {
        chart: {
          type: 'column', stacked: false
        },
        dataLabels: { enabled: false },
        strike: { width: [1, 1, 4] },
        title: {
          text: 'NET WORTH Aalysis', align: 'left', offsetX: 110
        },
        xaxis: {
          // categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016]
          categories: ['01/12']
        },
        yaxis: [
          {
            axisTicks: { show: true }, axisBorder: { show: true, color: '#008FFB' },
            labels: { style: { colors: '#008FFB' } },
            title: {
              text: "Income (thousand crores)",
              style: { color: '#008FFB' }
            },
            tooltip: { enabled: true }
          },
          {
            show: false
            // seriesName: 'Income',
            // opposite: true, axisTicks: { show:true }, axisBorder: { show: true, color:'#00E396' },
            // title: { 
            //   text: 'Operating Cashflow (thousand crores)',
            //   style: { colosr: '#00E396' }
            // }
          },
          {
            seriesName: 'Revenue',
            opposite: true,
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#FEB019'
            },
            labels: {
              style: {
                colors: '#FEB019',
              },
            },
            title: {
              text: "Revenue (thousand crores)",
              style: {
                color: '#FEB019',
              }
            }
          },
        ],
        tooltip: {
          fixed: {
            enabled: true,
            position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
            offsetY: 30,
            offsetX: 60
          },
        },
        legend: {
          horizontalAlign: 'left',
          offsetX: 40
        }
      },
      series: [
        {
          name: 'Income',
          type: 'column',
          data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
        }, {
          name: 'Expense',
          type: 'column',
          data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
        }, {
          name: 'Revenue',
          type: 'line',
          data: [20, 29, 37, 36, 44, 45, 50, 58]
        }
      ],
      chart: { series: [], options: ChartData.netWorthOptions([]) },
      showChart: true,
      showTransPreview: false, selectedTrans: [],
      previewPagination: { rowsPerPage: 0 },
      previewTransCol: [
        { label: "ACCOUNT", field: 'account', align: 'left' },
        { label: "DATE", field: 'date', align: 'left' },
        { label: "PAYEE", field: 'payee', align: 'left' },
        { label: "MEMO", field: 'memo', align: 'left' },
        { label: "ACTIVITY", field: 'activity', align: 'right' }
      ],
      summaryCol: [
        { label: 'CATEGORY', field: 'category', align: 'left' },
        { label: 'AMOUNT', field: 'amount', align: 'right' }
      ]
    }
  },

  computed: {
    ...mapState({
      linearProgress: state => state.app.linearProgress,
      query: state => state.db.query,
      trendQuery: state => state.db.trendQuery,
      lastRevenue: state => state.db.lastRevenue,
      currentBudget: state => state.db.currentBudget
    }),

    timeFilterLabel() {
      if (!this.timeFilter) return ''
      return `${date.formatDate(this.timeFilter.from, 'MMM YYYY')} - ${date.formatDate(this.timeFilter.to, 'MMM YYYY')}`
    },

    expense() { return this.query.filter(item => item._id !== "Income") },
    income() { return this.query.filter(item => item._id === "Income") },

    masterExpenses() {
      let query = this.query.filter(item => item._id !== 'Income')
      if (this.masterCatFilter) {
        query = query.find(item => item._id === this.masterCatFilter).subs
      }
      // chart callback
      const cb = (e, el, chart) => {
        let value = query[el[0].index]._id
        if (this.masterCatFilter && !this.subCatFilter) this.subCatFilter = value
        else if (this.masterCatFilter && this.subCatFilter) {
          if (this.subCatFilter === value) this.subCatFilter = null
          else this.subCatFilter = value
        }
        else this.masterCatFilter = value
        // console.log(this.masterCatFilter)
      }

      let title = this.masterCatFilter ? `${this.masterCatFilter} Expenses` : 'All Expenses'

      let chart = ChartData.masterExpenses(query)
      chart.options = ChartData.options(title, cb)
      return chart
    },
    trendsData() {
      let cats = []
      for (const item of this.trendQuery) {
        if (this.masterCatFilter) {
          let masterMatch = item.masters.find(cat => cat.master == this.masterCatFilter)
          if (!masterMatch) continue
          if (this.subCatFilter) {
            let subMatch = masterMatch.subs.find(s => s.sub == this.subCatFilter)
            if (!subMatch) continue
            if (!cats.includes(subMatch.sub)) cats.push(subMatch.sub)
          }
          else {
            for (const cat of masterMatch.subs) {
              if (!cats.includes(cat.sub)) cats.push(cat.sub)
            }
          }

        }
        else {
          for (const cat of item.masters) {
            if (!cats.includes(cat.master)) cats.push(cat.master)
          }
        }
      }
      return cats.map(cat => {
        return {
          name: cat,
          data: this.trendQuery.map(item => {
            if (!this.masterCatFilter) {
              let match = item.masters.find(m => m.master == cat)
              return {
                cat: match,
                amount: parseInt(match ? match.activity * -1 : 0)
              }
            }
            else {

              let masterMatch = item.masters.find(m => m.master == this.masterCatFilter)
              if (!masterMatch) return { amount: 0 }

              if (!this.subCatFilter) {
                let subMatch = masterMatch.subs.find(s => s.sub == cat)
                return {
                  cat: subMatch,
                  amount: parseInt(subMatch ? subMatch.activity * -1 : 0)
                }
              }
              else {
                let subMatch = masterMatch.subs.find(s => s.sub == this.subCatFilter)
                if (!subMatch) return { amount: 0 }
                else return {
                  cat: subMatch,
                  amount: parseInt(subMatch.activity * -1)
                }
              }

            }
          })
        }
      })
    },
    masterExpensesTrend() {
      // chart callback
      const cb = (e, ctx, config) => {
        let trendsData = this.trendsData
        let item = trendsData[config.seriesIndex]?.data[config.dataPointIndex]
        // console.log({item, config, trendsData})
        if (!item) return
        // console.log({ cat: item.cat, amount: item.amount, data: trendsData[config.seriesIndex]?.data })
        if (item.cat.master) this.masterCatFilter = item.cat.master
        else if (item.cat.sub) {
          if(!this.subCatFilter)this.subCatFilter = item.cat.sub
          else this.selectedTrans = item.cat.trans
        }
      }

      let title = this.masterCatFilter ? `${this.masterCatFilter} Expenses` : 'All Expenses'
      let data = this.trendsData.map(cat => {
        return {
          ...cat,
          data: cat.data ? cat.data.map(item => item.amount) : []
        }
      })
      let chart = ChartData.masterExpensesTrend(data, this.trendQuery.map(item => item.dateKey), cb)
      return {
        series: chart.series || [],
        options: chart.options
      }
    },
    trendStats(){
      let trendsData = this.trendsData.map(cat => {
        return {
          ...cat,
          data: cat.data ? cat.data.map(item => item.amount) : []
        }
      })
      let accum = []
      for(const item of this.trendQuery){
        accum.push({ data: [] })
      }
      for(const item of trendsData){
        item.data.forEach((val, ind) => {
          if(accum[ind]?.data)accum[ind].data.push(val)
        })
      }
      // console.log(accum)
      let summed = accum.map(acc => {
        return acc.data.reduce((t,a) => { return t + a }, 0)
      })
      let length = this.trendQuery.length
      let total = summed.reduce((tot, a) => { return tot + a }, 0)
      return {
        avg: parseInt(total / length),
        min: Math.min(...summed),
        max: Math.max(...summed)
      }
    },

    summaryData() {
      if (!this.masterCatFilter) {
        return this.expense.map(item => {
          return { category: item._id, amount: parseInt(item.activity).toLocaleString('de-DE') }
        })
      } else {
        let subcat = this.expense.find(item => item._id === this.masterCatFilter)
        return !this.subCatFilter ? 
          subcat.subs.map(item => { return { category: item._id, amount: item.activity } }) :
          subcat.subs.filter(item => item._id == this.subCatFilter)
            .map(item => { return { category: item._id, amount: item.activity } })
      }
    },

    netExpense() {
      let trans = []
      this.expense.map(master => {
        master.subs.map(sub => {
          trans = [...trans, ...sub.trans]
        })
      })
      trans = trans.map(item => {
        return {
          ...item, activity: parseInt(item.activity)
        }
      })
      return ChartData.groupMonth(trans)
    },
    netIncome() {
      let trans = []
      this.income.map(master => {
        master.subs.map(sub => {
          trans = [...trans, ...sub.trans]
        })
      })
      trans = trans.map(item => {
        return {
          ...item, activity: parseInt(item.activity)
        }
      })
      return ChartData.groupMonth(trans)
    },
    netWorth() {
      const chart = ChartData.netWorth(this.netIncome, this.netExpense, this.lastRevenue, this.currentBudget)
      return chart
    },
    netWorthOptions() {
      let xData = []
      this.netIncome.map(item => {
        if (!xData.includes(item._id)) xData.push(item._id)
      })
      this.netExpense.map(item => {
        if (!xData.includes(item._id)) xData.push(item._id)
      })
      return ChartData.netWorthOptions(xData)
    },

    previewTrans() {

      return this.selectedTrans
        // .sort((a, b) => {
        //   return a.activity - b.activity
        // })  
        .map(item => {
          return {
            ...item,
            date: date.formatDate(item.date, 'DD/MM/YYYY'),
            activity: item.activity.toLocaleString('de-DE')
          }
        })

    }

  },
  watch: {
    netWorth(data) {
      // console.log('watch netWorth', data.series[0].data.length, data)
      const revenue = data.series[2].data
      const revMin = Math.min(...revenue.map(item => item.y))
      const revMax = Math.max(...revenue.map(item => item.y))
      const revSpan = revMax - revMin
      const midOffset = revMin < 0 ? Math.abs(100 - parseInt((0 - revMin) * 100 / revSpan)) : null
      const midStop = midOffset && midOffset > 0 ? { offset: midOffset, color: 'orange', opacity: 0.4 } :
        { offset: 99, color: 'yellow', opacity: 0.4 }
      let colorStop = [
        { offset: 0, color: revMax > 0 ? 'lightgreen' : 'red', opacity: revMax > 0 ? 0.8 : 0.2 },
        midStop,
        // { ...midStop, offset: midStop.offset + 1, opacity: 0.6 },
        { offset: 100, color: revMin < 0 ? 'red' : 'white', opacity: 1 },
      ]
      // // console.log('watch', revMin, revMax, revSpan, midOffset, colorStop)
      let opts = data.options
      opts.fill.gradient.colorStops = [
        [
          { offset: 0, color: '#00FB8F', opacity: 0.8 },
          { offset: 100, color: '#00FB8F', opacity: 0.8 },
        ],
        [
          { offset: 0, color: '#008FFB', opacity: 0.8 },
          { offset: 100, color: '#008FFB', opacity: 0.8 },
        ],
        colorStop
      ]
      if (this.$refs.netWorthChart) {
        this.$refs.netWorthChart.updateSeries(data.series)
        // this.$refs.netWorthChart.updateOptions({
        //   xaxis: data.options.xaxis
        // })
        this.$refs.netWorthChart.updateOptions(opts)
      }
    },
    panel(newVal) {
      // console.log('panel', newVal)
      this.getData()
      this.selectedTrans = []
    }
  },
  methods: {
    getData() {
      // console.log(this.timeFilter, this.categoryFilter, this.accountFilter)
      this.masterCatFilter = null
      let query = {}

      if (this.timeFilter) {
        let date = {}
        if (this.timeFilter.from) date["$gte"] = this.timeFilter.from
        if (this.timeFilter.to) date["$lt"] = this.timeFilter.to
        query.date = date
      }

      if (this.categoryFilter.length > 0) {
        query.categoryId = { "$in": this.categoryFilter }
      }

      if (this.accountFilter.length > 0) {
        query.from = { "$in": this.accountFilter }
      }

      // console.log(query)
      this.$store.dispatch("db/searchTransactions", query)
      // let chart = this.netWorth
      // this.chart = {
      //   ...this.chart,
      //   series: chart.series,
      //   options: {
      //     ...this.chart.options,
      //     xaxis: {
      //       ...this.chart.options.xaxis,
      //       categories: chart.options.xaxis.categories
      //     }
      //   }
      // }
      // this.chart = chart
      // console.log(this.showChart, this.chart)
      // this.showChart = true

    },

    networthClick(event, chartContext, config) {
      // console.log('networthClick', event, chartContext, config)
      // console.log(config.seriesIndex, config.dataPointIndex)

      this.selectedTrans = config.seriesIndex == 0 ? this.netIncome[config.dataPointIndex].trans :
        this.netExpense[config.dataPointIndex].trans
      this.selectedTrans.sort((a, b) => {
        return config.seriesIndex == 0 ? b.activity - a.activity : a.activity - b.activity
      })
    },

    resetCatFilter(){
      if(this.subCatFilter)this.subCatFilter = null
      else if(this.masterCatFilter)this.masterCatFilter = null
      this.selectedTrans = []
    }
  },
  mounted() {
    this.getData()
    if(this.currentBudget.length < 1)this.$store.dispatch('db/getCurrentBudget')
  }
}
</script>

<style></style>