<template>
  <q-layout view="lHh Lpr lFf">

    <q-drawer v-if="!$q.platform.is.mobile && authorized" v-model="leftDrawerOpen" show-if-above bordered
      class="no-scroll bg-blue-grey-10 text-white">

      <div class="column full-height">

        <!-- User Account -->
        <div class="col-auto">
          <q-item clickable>
            <q-item-section avatar>
              <img style="max-width:3rem"
                src="https://play-lh.googleusercontent.com/3670OMYEbgCVyV1fGdkErGmYIpLtQ7_70LPcXKRQCjfbd819VzSk3b5rYVcQ8aOoCAM" />
            </q-item-section>
            <q-item-section>
              <q-item-label>My Budget</q-item-label>
              <q-item-label class="text-white" v-if="authorized" caption>{{ this.user ? this.user.email : 'email'
                }}</q-item-label>
            </q-item-section>
            <q-menu>
              <q-list>
                <q-item clickable v-close-popup @click="authorizeMobile">
                  <q-item-section>Authorize Mobile App</q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="editPayeesDialog = true">
                  <q-item-section>Manage Payees</q-item-section>
                </q-item>

                <q-separator />
                <q-item clickable v-close-popup>
                  <q-item-section @click="authorized ? $router.push('login') : ''">{{ authorized ? 'Login' : 'Logout'
                    }}</q-item-section>
                </q-item>
                <q-separator />
              </q-list>
            </q-menu>
          </q-item>
        </div>

        <!-- Total Amount -->
        <div class="col-auto">
          <q-item>
            <!-- <q-item-section avatar>
              <q-icon name="fa fa-solid fa-landmark"/>
            </q-item-section> -->
            <q-item-section>
              <q-chip text-color="white" color="green" icon-right="fa fa-solid fa-landmark" 
                 size="lg" square :label="totalSum + ' LEK'"></q-chip>
            </q-item-section>
          </q-item>
        </div>

        <!-- Main Menu -->
        <div class="col-auto">
          <q-item v-for="item in mainMenu" :key="item.label" @click="item.click" clickable
            :class="(item.name === lastPage || (item.name === lastAccount && lastPage === 'ledger')) && 'bg-primary text-white'">
            <q-item-section avatar>
              <q-icon :name="item.icon" />
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ item.label }}</q-item-label>
            </q-item-section>
          </q-item>
        </div>

        <!-- Account List -->
        <q-list class="col scroll hide-scrollbar">

          <account-expandable-menu :data="budgetAccounts" title="BUDGET" :expanded="true" @editAccount="editAccount" />
          <account-expandable-menu :data="loanAccounts" title="LOANS" :expanded="true" @editAccount="editAccount" />
          <account-expandable-menu :data="trackAccounts" title="TRACKING" :expanded="true" @editAccount="editAccount" />
          <account-expandable-menu :data="receivableAccounts" title="RECEIVABLE" @editAccount="editAccount" />
          <account-expandable-menu :data="liabilityAccounts" title="LIABILITY" @editAccount="editAccount" />
          <account-expandable-menu :data="closedAccounts" title="CLOSED" @editAccount="editAccount" />

        </q-list>

        <!-- Action buttons -->
        <div class="col-auto bg-secondary">
          <q-item>
            <q-btn icon="add" class="q-pr-md" flat label="Add Account" @click="createAccount"></q-btn>
            <q-btn icon="refresh" flat @click="refreshAccounts" />
          </q-item>
        </div>

      </div>

    </q-drawer>

    <q-page-container>
      <router-view></router-view>
    </q-page-container>

    <!-- Edit Account Dialog -->
    <q-dialog v-model="editAccDialog">
      <ledger-account-edit :account="editAcc" @dismiss="editAccDialog = false" />
    </q-dialog>

    <!-- Authorize Dialog -->
    <q-dialog v-model="alert">

      <q-card>
        <q-card-section>
          <div class="text-h5">User Tokens</div>
        </q-card-section>
        <q-card-section>
          <q-list>
            <q-item>Scan QR to authorize your mobile APP</q-item>
            <q-item>
              <qrcode-vue :value="tempUserToken.token || ''" size="300" level="M" render-as="svg" />
            </q-item>
            <q-item v-for="token in userTokens" :key="token.id">
              <q-item-section>
                timespan: {{ token.timespan }}, deviceId: {{ token.deviceId || 'null' }},
                valid: {{ token.valid === false ? token.valid : true }}
              </q-item-section>
              <q-separator />
            </q-item>
          </q-list>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <!-- Manage Payees Dialog -->
    <q-dialog v-model="editPayeesDialog">
      <manage-payees-vue style="min-width: 80rem; min-height: 80vh;" />
    </q-dialog>

  </q-layout>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { mapGetters, mapState } from 'vuex'
import apiService from './services/api.service'

import AccountExpandableMenu from './components/AccountExpandableMenu.vue'
import LedgerAccountEdit from './components/Dialogs/LedgerAccountEdit.vue'
import ManagePayeesVue from './components/Dialogs/ManagePayees.vue'

export default {
  name: 'LayoutDefault',

  components: {
    QrcodeVue,
    AccountExpandableMenu,
    LedgerAccountEdit,
    ManagePayeesVue
  },

  data() {
    return {
      mainMenu: [
        { icon: 'fa fa-solid fa-coins', label: 'Budget', click: this.getBudget, name: 'budget' },
        { icon: 'fa fa-solid fa-chart-line', label: 'Report', click: this.getReport, name: 'report' },
        { icon: 'fa fa-solid fa-landmark', label: 'All Accounts', name: '*', click: () => { this.getTransactions() } },
      ],
      leftDrawerOpen: true,
      userTokens: [], tempUserToken: {}, alert: false,
      editAccDialog: false, editPayeesDialog: false,
      editAcc: {}
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      accounts: state => state.db.accounts,
      lastPage: state => state.app.lastPage,
      lastAccountId: state => state.app.lastAccountId,
      rates: state => state.db.rates
    }),
    ...mapGetters({
      budgetAccounts: 'db/budgetAccounts',
      closedAccounts: 'db/closedAccounts',
      trackAccounts: 'db/trackAccounts',
      loanAccounts: 'db/loanAccounts',
      receivableAccounts: 'db/receivableAccounts',
      liabilityAccounts: 'db/liabilityAccounts',
      lastAccount: 'app/lastAccount',
      authorized: 'auth/authorized',
      totalSum: 'db/allAccountsSum'
    }),
    budgetAccSum() { return this.activeBudgetAccounts.reduce((total, item) => { return total + item.balance }, 0) },
  },

  methods: {

    getTransactions(accountId) {
      // console.log('getTransactions', accountId)
      // this.$store.dispatch('db/getTransactions', accountId)
      this.$store.commit('app/setLastPage', 'ledger')
      this.$store.commit('app/setLastAccount', accountId)
      this.$router.push(`/ledger?accountId=${accountId}`)
    },

    getBudget() {
      this.$store.commit('app/lastPage', 'budget')
      this.$router.push('/budget')
    },

    getReport() {
      this.$store.commit('app/lastPage', 'report')
      this.$router.push('/report')
    },

    refreshAccounts() {
      this.$store.dispatch('db/getAccounts')
    },

    getMenuClass(page) {
      return this.lastPage === page ? 'bg-primary text-white' : ''
    },

    authorizeMobile() {
      apiService.getUserTokens()
        .then(res => {
          if (res.status === 200) {
            let userTokens = res.data
            this.userTokens = userTokens.map(token => {
              return {
                id: token.id, valid: token.valid, timespan: token.timespan, deviceId: token.deviceId
              }
            })
            apiService.getTempToken()
              .then(res => {
                if (res.status !== 200) return
                this.tempUserToken = res.data
              })
            this.alert = true
          }
        })
        .catch(err => apiService.handleError(err))
    },

    createAccount() {
      this.editAcc = {}
      this.editAccDialog = true
    },

    editAccount($event) {
      console.log('emit editAccount', $event)
      const account = this.accounts.find(item => item._id == $event)
      if (!account) return
      this.editAcc = { ...account }
      this.editAccDialog = true
    },

    refreshAll() {
      this.$store.dispatch('db/getAccounts')
      this.$store.dispatch('db/getCategories')
      this.$store.dispatch('db/getPayees')
      this.$store.dispatch('db/getPayeeList')
    }
  },

  mounted() {
    if (this.authorized) this.$store.dispatch('auth/refreshToken').then(res => {
      this.refreshAll()
      // if(this.lastPage === 'ledger')this.getTransactions(this.lastAccountId)
    })
    let lastRoute = `/${this.lastPage}`
    console.log('lastRoute', lastRoute)
    // this.$router.push(lastRoute)
    if(!this.rates.USD)this.$store.dispatch('db/getRates')
  }

}
</script>

<style>
* {
  font-size: 1rem;
}

/* .q-table td {font-size: large;} */
</style>