import { createRouter, createWebHashHistory } from "vue-router";
import Platform from 'quasar/src/plugins/Platform.js';;
import store from "@/store"
import Home from '@/pages/Home'
import Login from '@/pages/Login'
import Ledger from '@/pages/Ledger'
import Budget from '@/pages/Budget'
import Report from '@/pages/Report'
import Mobile from '@/pages/HomeMobile'

const routes = [
    {path: '/', component: Home},
    {path: '/login', component: Login},
    {path: '/ledger', component: Ledger},
    {path: '/budget', component: Budget},
    {path: '/report', component: Report},
    {path: '/mobile', component: Mobile},
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    // console.log('router', from.path, to.path)
    switch(to.path){
        case '/login': {
            store.commit('auth/logout')
        }
    }
    if(to.path !== '/login'){
        if(!store.getters['auth/authorized']) next('/login')
        else {
            if(Platform.is.mobile)next('/mobile')
            else next()
        }
    }
    else next()
})

export default router