<template>
    <q-expansion-item class="col" switch-toggle-side expand-separator :default-opened="expanded" :label="title">
        <template v-if="title !== 'CLOSED'" v-slot:header>
            <q-item-section>{{ title }}</q-item-section>
            <q-item-section side>
                <!-- <q-item-label >{{ data.sum }}</q-item-label> -->
                <q-chip text-color="white" :color="data.sum < 0 ? 'negative' : 'positive'">{{ data.sum }}</q-chip>
            </q-item-section>
        </template>
        <q-list separator class="">
            <q-item class="row" v-for="item in data.accounts" :key="item._id"
                :class="(item._id === lastAccountId && lastPage === 'ledger') && 'bg-primary text-white'"
                @mouseenter="accountHover = true; accountHoverId = item._id" @mouseleave="accountHover = false"
                @click="getTransactions(item._id)" clickable>
                <q-item-section avatar class="">
                    <q-icon v-show="accountHover && accountHoverId == item._id" 
                        color="white" name="edit" 
                        @click="editAccount(item._id)" @mouseenter="editAccHover = true" @mouseleave="editAccHover = false"/>
                    
                </q-item-section>
                <q-item-section>
                    <q-item-label>{{ item.account }}</q-item-label>
                </q-item-section>
                <q-space />
                
                <q-item-section side top>
                    <q-item-label v-if="item.balance >= 0" :class="item.balance == 0 ? 'text-white' : 'text-light-green-14'">{{ displayBalance(item) }}</q-item-label>
                    <q-chip text-color="red" v-else-if="item.balance < 0" color="white">{{ displayBalance(item) }}</q-chip>
                    <q-item-label caption class="text-white">{{ convertBalance(item) }}</q-item-label>
                </q-item-section>
                
            </q-item>
        </q-list>
    </q-expansion-item>
</template>

<script>
import { mapState } from 'vuex'

export default {

    data() {
        return {
            accountHover: false,
            accountHoverId: '',
            editAccHover: false
        }
    },

    props: {
        data: Array,
        title: String,
        expanded: Boolean
    },

    computed: {
        ...mapState({
            lastPage: state => state.app.lastPage,
            lastAccountId: state => state.app.lastAccountId,
            rates: state => state.db.rates
        }),

    },

    methods: {
        displayBalance(account){
            if(!account.currency || account.currency == 'LEK')return parseFloat(account.balance).toLocaleString('de-DE')
            return `${account.balance} ${account.currency}`
            // const rate = this.rates[account.currency]
            // if(!rate)return parseFloat(account.balance).toLocaleString('de-DE')
            // return `${account.balance} ${account.currency}\n (${ parseFloat(parseFloat(account.rawBalance * rate).toFixed(0)).toLocaleString('de-DE') })`
        },
        convertBalance(account){
            const rate = this.rates[account.currency]
            return rate ? `(${parseFloat(parseFloat(account.rawBalance * rate).toFixed(0)).toLocaleString('de-DE')} LEK)` : ''
        },

        getTransactions(accountId) {
            if(this.editAccHover)return
            this.$store.commit('app/setLastPage', 'ledger')
            this.$store.commit('app/setLastAccount', accountId)
            this.$router.push(`/ledger?accountId=${accountId}`)
        },

        editAccount(accountId) {
            this.$emit('editAccount', accountId)
        }
    }
}

</script>