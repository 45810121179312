<template>
  <q-page class="position-relative">
    <!-- Login Form -->
    <q-form ref="loginForm" class="q-mb-xl q-pa-md shadow-2 absolute-center" style="max-width: 30rem; width: 100%"
      @submit.prevent="login" @reset="onReset" autocorrect="off" autocapitalize="off" autocomplete="false"
      spellcheck="false">
      <q-input outlined type="text" label="username or email *" v-model="username" class="q-my-sm" lazy-rules="true"
        @blur="checkUserOTP" :rules="[
          (val) =>
            (val !== null && val !== '') ||
            'Please type your username or email',
        ]" />

      <q-input outlined :type="passwordType" label="password *" v-model="password" class="q-mb-sm" lazy-rules="true"
        :rules="[
          (val) => (val !== null && val !== '') || 'Password cannot be null',
        ]">
        <template v-slot:append>
          <q-icon :name="passwordIcon" @click="viewPassword = !viewPassword" />
        </template>
      </q-input>

      <!-- <q-input v-if="showPinOTP" outlined type="text" label="OTP pin *" v-model="otpPin" class="q-mb-sm">

      </q-input> -->

      <q-btn class="fit q-py-md q-mb-xl" type="submit" color="primary" label="Login"></q-btn>

      <q-banner v-if="showLoginError" inline-actions class="text-white bg-red">
        {{ loginError }}

        <template v-slot:action>
          <q-btn flat icon="close" @click="showLoginError = false" />
        </template>
      </q-banner>

    </q-form>

    <!-- Authorize Dialog -->
    <q-dialog v-model="showSetupQR">

      <q-card>
        <q-card-section>
          <div class="text-h5">Setup OTP Authentication</div>
        </q-card-section>
        <q-card-section>
          <q-list>
            <q-item>Scan QR with your google authenticator APP</q-item>
            <q-item v-if="otpQR == ''">
              <q-spinner color="primary" size="3em" class="q-mx-auto" />
            </q-item>
            <q-img v-else :src="otpQR" />
          </q-list>
        </q-card-section>
        <q-separator />
        <q-card-section>
          <div class="text-h6">After pairing with Google Authenticator enter code shown in the app to confirm</div>
          <v-otp-input :num-inputs="6" v-model:value="otpPin" :placeholder="['*', '*', '*', '*', '*', '*']"
            input-classes="otp-input" separator="-" inputType="numeric" @on-complete="checkUserOTP" />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup @click="confirmOTPsetup" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="showOTPinput">
      <q-card>
        <q-card-section>
          <div class="text-h5">Enter Code from Google Authenticator</div>
        </q-card-section>
        <q-card-section>
          <v-otp-input
            :num-inputs="6" v-model:value="otpPin" :placeholder="['*', '*', '*', '*', '*', '*']"
            input-classes="otp-input" separator="-" inputType="numeric" @on-complete="checkUserOTP"
          />
          <p v-if="showInvalidOTP" class="text-danger">Invalid OTP password. Try again!</p>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-page>
</template>

<script>
import { mapState } from 'vuex';
import VOtpInput from 'vue3-otp-input';
export default {
  name: "LoginPage",
  components: {
    VOtpInput
  },

  data() {
    return {
      username: "",
      password: "",
      otpPin: "",
      viewPassword: false,
      loginSemafor: false,
      loginError: null,
      showLoginError: false,
      showSetupQR: false, showOTPinput: false, showInvalidOTP: false
    };
  },

  computed: {
    ...mapState({
      userOTP: state => state.auth.otp,
      otpQR: state => state.auth.otpQR
    }),
    showPinOTP() { return this.userOTP && this.userOTP.activated },
    passwordType() {
      return this.viewPassword ? 'text' : 'password'
    },
    passwordIcon() {
      return this.viewPassword ? 'visibility_off' : 'visibility'
    }
  },

  methods: {
    login() {
      if (this.loginSemafor) return;
      this.loginSemafor = true;
      this.$refs.loginForm.validate().then((valid) => {
        // console.log("form validation", valid);
        if (!valid) return;
        this.$store
          .dispatch("auth/login", {
            email: this.username,
            password: this.password,
            // otp: this.showPinOTP ? this.otpPin : undefined
          })
          .then((data) => {

            if (data.otp && data.otpComplete == false) {
              this.$store.dispatch('auth/getGoogleAuthPairQR')
              this.showSetupQR = true
              return
            }
            else if(data.otp && data.otpComplete == true) {
              this.showOTPinput = true
              return
            }

            this.$store.dispatch('db/getAccounts')
            this.$store.dispatch('db/getCategories')
            this.$store.dispatch('db/getPayees')
            this.$router.push(`/${this.$store.state.app.lastPage}`);
          })
          .catch((error) => {
            // alert(error.response.data)
            this.$refs.loginForm.reset();
            if (error && error.response && error.response.data?.message) {
              this.loginError = error.response.data?.message
              this.showLoginError = true
            }
          })
          .finally(() => {
            this.loginSemafor = false;
          });
      });
    },

    checkUserOTP() {
      // console.log(this.otpPin); return;
      // if (this.showPinOTP) return
      // this.$store.dispatch('auth/getUserOTP', this.username)
      this.$store.dispatch('auth/verifyOTP', this.otpPin)
        .then(res => {
          this.$store.dispatch('db/getAccounts')
          this.$store.dispatch('db/getCategories')
          this.$store.dispatch('db/getPayees')
          this.$router.push(`/${this.$store.state.app.lastPage}`);
        })
        .catch(err => {
          this.showInvalidOTP = true
        })
    },

    confirmOTPsetup() {

      this.$store.dispatch('auth/confirmGoogleAuthPairQR')
        .finally(() => {
          this.$store.commit('auth/logout')
          this.$router.push('/login')
        })
    },

    onReset() {
      this.username = null;
      this.password = null;
    },
  },

  mounted() {
    this.showLoginError = false
    this.showSetupQR = false
  },
};
</script>

<style>
.otp-input {
  width: 3rem;
  height: 4rem;
  padding: 5px;
  margin: 0 10px;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

/* Background colour of an input field with value */
.otp-input.is-complete {
  background-color: #e4e4e4;
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Causes issue globally with inputs */
/* input::placeholder {
  font-size: 1.8rem;
  text-align: center;
  font-weight: 600;
} */
</style>