<template>
  <h3>Account</h3>
</template>

<script>
export default {
    name:'AccountMobile'
}
</script>

<style>

</style>