<template>
    <q-card>
        <q-card-section>
            <div class="text-h5">Manage Payees</div>
        </q-card-section>
        <q-card-section>
            <div class="row">

                <div class="col-3">
                    <q-input v-model="search" label="Search payee">
                        <template v-slot:prepend>
                            <q-icon name="search" />
                        </template>
                    </q-input>
                    <q-checkbox v-model="hasSelectedPayees" :label="payeesCheckLabel" @click="selectAll" />
                    <hr />
                    <div>
                        <q-list dense>
                            <q-item v-for="payee in filteredPayees" :key="payee._id">
                                <q-item-section side top>
                                    <q-checkbox v-model="payee.selected" />
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ payee.payee }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </div>
                </div>

                <div class="col-9">

                    <!-- Single payee edit -->
                    <div v-if="selectedPayees.length == 1">
                        <div class="text-h6">{{ selectedPayees[0].payee }}</div>
                        <div>Autofill Options:</div>
                        <hr/>
                        <q-list>
                            <q-item>
                                <q-item-section side top>
                                    <q-checkbox v-model="selectedPayees[0].list" @click="includePayeeList"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>Include in Payee List</q-item-label>
                                    <q-item-label>
                                        If enabled, this payee will be suggested as you type and will be shown
                                        in the list of payees when adding transactions.
                                    </q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item>

                                <q-item-section>
                                    <q-item-label>Automatically categorize payee</q-item-label>
                                    <q-item-label>
                                        If enabled, transactions with this payee will automatically receive the selected category.
                                    </q-item-label>
                                    <q-select label="Category"/>
                                </q-item-section>
                                
                            </q-item>
                        </q-list>
                        <q-btn label="Delete" color="negative" @click="deletePayees"/>
                    </div>

                    <!-- Multiple payee edits -->
                    <div v-else-if="selectedPayees.length > 1">
                        <div class="text-h6">{{ selectedPayeesLabel }}</div>
                        <div class="text-h7">{{ selectedPayeesNames }}</div>
                        <div>Autofill Options:</div>
                        <hr/>
                        <q-list>
                            <q-item>
                                <q-item-section side top>
                                    <q-checkbox v-model="listSelectedPayees" @click="includePayeeList"/>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>Include in Payee List</q-item-label>
                                    <q-item-label>
                                        If enabled, this payee will be suggested as you type and will be shown
                                        in the list of payees when adding transactions.
                                    </q-item-label>
                                </q-item-section>
                            </q-item>

                            <q-item>

                                <q-item-section>
                                    <q-item-label>Combine and rename selected payees</q-item-label>
                                    <q-input v-model="mergeName" >
                                    <template v-slot:after>
                                        <q-btn label="Combine" @click="mergePayees"/>
                                    </template>
                                </q-input>
                                </q-item-section>
                            </q-item>
                        </q-list>
                        <q-btn label="Delete" color="negative" @click="deletePayees"/>
                    </div>

                    <div v-else>No payees seleted...</div>
                </div>

            </div>
        </q-card-section>
    </q-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            search: '',
            hasSelectedPayees: false,
            listSelectedPayees: false,
            mergeName: ''
        }
    },

    props: {

    },

    computed: {
        ...mapState({
            payees: state => state.db.payees
        }),

        selectedPayees() {
            return this.payees.filter(payee => !!payee.selected)
        },

        filteredPayees() {
            return this.payees.filter(payee => payee.payee.toLowerCase().includes(this.search.toLowerCase())).slice(0, 20)
        },

        payeesCheckLabel() {
            return `Payees ${this.selectedPayees.length > 0 ? `(${this.selectedPayees.length})` : ''}`
        },

        selectedPayeesLabel() {
            return `${this.selectedPayees.length} Payees Selected`
        },
        selectedPayeesNames(){
            return this.selectedPayees.reduce((result, item) => { return `${result}${result != '' ? ', ' : ''}${item.payee}` }, '')
        }
    },

    watch: {
        selectedPayees(val) {
            this.hasSelectedPayees = val.length > 0
        },

    },

    methods: {
        selectAll() {
            if (this.hasSelectedPayees == true) this.$store.commit('db/selectPayees', this.filteredPayees.map(item => item._id))
            else this.$store.commit('db/selectPayees', [])
        },

        includePayeeList(){
            if(this.selectedPayees.length == 0)return
            else if(this.selectedPayees.length == 1){
                this.$store.dispatch('db/updatePayee', { id: this.selectedPayees[0]._id, list: this.selectedPayees[0].list })
            }
            else {
                this.$store.dispatch('db/updatePayees', {
                    query: { _id: { $in: this.selectedPayees.map(item => item._id) } },
                    update: { list: this.listSelectedPayees }
                })
            }
        },

        deletePayees(){
            if(this.selectedPayees.length == 0)return
            this.$store.dispatch('db/deletePayees', this.selectedPayees.map(item => item._id))
        },
         mergePayees(){
            if(this.mergeName == '')return
            this.$store.dispatch('db/mergePayees', {
                payees: this.selectedPayees.map(item => item._id),
                rename: this.mergeName
            })
         }
    },

    mounted(){
        this.$store.commit('db/selectPayees', [])
    }
}

</script>