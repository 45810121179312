import Vuex from 'vuex'
import app from './app.module'
import auth from './auth.module'
import db from './db.module'

const store = Vuex.createStore({
    modules: {
        app, auth, db
    }
})

export default store