<template>
  <h3>Report</h3>
</template>

<script>
export default {
    name:'ReportMobile'
}
</script>

<style>

</style>