<template>
  <q-page class="position-relative">

    <q-tab-panels v-model="tab" animated >
        <q-tab-panel name="budget">
            <BudgetMobile/>
        </q-tab-panel>
        <q-tab-panel name="account">
            <AccountMobile/>
        </q-tab-panel>
        <q-tab-panel name="transaction">
            <TransactionMobile/>
        </q-tab-panel>
        <q-tab-panel name="report">
            <ReportMobile/>
        </q-tab-panel>
        <q-tab-panel name="help">
            <HelpMobile/>
        </q-tab-panel>
    </q-tab-panels>
    <q-space/>
    <q-tabs v-model="tab" dense no-caps class="absolute-bottom bg-accent text-white">
        <q-tab name="budget" icon="fa fa-coins" label="Budget"/>
        <q-tab name="account" icon="account_balance" label="Account"/>
        <q-tab name="transaction" icon="add" label="Transaction" />
        <q-tab name="report" icon="fa fa-chart-line" label="Report"/>
        <q-tab name="help" icon="help" label="Help"/>
    </q-tabs>

  </q-page>
</template>

<script>

import TransactionMobile from "@/pages/TransactionMobile.vue"
import ReportMobile from "./ReportMobile.vue"
import HelpMobile from "./HelpMobile.vue"
import BudgetMobile from "./BudgetMobile.vue"
import AccountMobile from "./AccountMobile.vue"


export default {
    name:'MobilePage',

    components:{
        TransactionMobile,
        ReportMobile,
        HelpMobile,
        BudgetMobile,
        AccountMobile
    },

    data(){
        return {
            tab:'transaction',
        }
    },

    computed: {

    },

    methods: {
        
    },

    mounted(){
        let lastTab = localStorage.getItem('last-mobile-tab')
        if(lastTab)this.tab = lastTab
    }
}
</script>

<style>

</style>