<template>
  <h3>Budget</h3>
</template>

<script>
export default {
    name:'BudgetMobile'
}
</script>

<style>

</style>