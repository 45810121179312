import API from '../services/api.service'
import DataChart from '../utilities/chart'

const db = {
    namespaced: true,

    state: {
        accounts: [],
        categories: [],
        payees: [], payeeList: [],
        transactions: [], query: [], trendQuery: [], lastRevenue: 0,
        currentBudget: [],
        budget: [],
        rates: {}
    },

    actions: {

        getRates({ commit }) {
            API.getRates().then(res => commit('setRates', res.data))
                .catch(err => API.handleError(err))
        },

        getAccounts({ commit }) {
            API.getAccounts({}).then(res => {
                // console.log('api accounts', res.data)
                commit('setAccounts', res.data)
            }).catch(error => API.handleError(error))
        },

        addAccount({ commit }, account) {
            API.addAccount(account)
                .then(res => commit('addAccount', res.data))
                .catch(err => API.handleError(err))
        },

        editAccount({ commit }, account) {
            API.editAccount(account._id, account)
                .then(res => commit('setAccount', res.data))
                .catch(err => API.handleError(err))
        },

        deleteAccount({ commit }, accountId) {
            API.deleteAccount(accountId)
                .then(res => commit('delAccount', accountId))
                .catch(err => API.handleError(err))
        },

        // Transactions
        getTransactions({ commit }, accountId) {

            let date = new Date()
            date.setFullYear(date.getFullYear() - 1)

            let data = {
                query: {
                    date: { '$gte': date.toISOString() }
                },
                sort: { date: -1 },
            }
            if (accountId) data.query.from = accountId
            // console.log('getTransactions', data)
            this.commit('app/setLinearProgress', true)
            API.getTransactions(data)
                .then(res => {
                    commit('setTransactions', res.data)
                })
                .catch(error => API.handleError(error))
                .finally(() => { this.commit('app/setLinearProgress', false) })
        },

        queryTransactions({ commit }, query) {
            this.commit('app/setLinearProgress', true)
            API.getTransactions({ query, sort: { date: -1 } }).then(res => {
                this.commit('app/setLinearProgress', false)
                commit('setTransactions', res.data)
            }).catch(error => API.handleError(error))
        },

        searchTransactions({ commit }, query) {
            this.commit('app/setLinearProgress', true)
            API.searchTransactions(query).then(res => {
                // console.log(res.data)
                commit('setTransQuery', res.data)
                this.commit('app/setLinearProgress', false)
            }).catch(err => API.handleError(err))
        },

        addTransaction({ commit, dispatch }, data) {
            return API.addTransaction(data).then(res => {
                console.log('addTransaction', res.data)
                commit('addTransaction', res.data)
                dispatch('getAccounts')
                dispatch('getPayees')
                // commit('updateAccount', res.data.from)
                // if(data.to)commit('updateAccount', res.data.to)
                // // commit('updateBudget', res.data.budget)
                return Promise.resolve(res.data)
            }).catch(error => {
                API.handleError(error)
                return Promise.reject(error)
            })
        },

        updateTransaction({ commit, dispatch }, data) {
            API.updateTransaction(data).then(res => {
                console.log('update', res.data)
                dispatch('getAccounts')
            }).catch(error => {
                API.handleError(error)
            })
        },

        delTransaction({ commit, dispatch }, id) {
            API.delTransaction(id).then(res => {
                console.log('delTransaction', res.data)
                commit('removeTransaction', id)
                dispatch('getAccounts')
                // commit('updateAccount', res.data.account)
                // if(res.data.transferAccount)commit('updateAccount',res.data.transferAccount)
            }).catch(error => {
                API.handleError(error)
            })
        },

        clearTransactions({ commit }, data) {
            API.clearTransactions(data).then(res => {
                console.log(res.data)
                commit('clearTransactions', data)
            }).catch(error => {
                API.handleError(error)
            })
        },

        flagTransactions({ commit }, data) {
            API.flagTransactions(data).then(res => {
                console.log(res.data)
                commit('flagTransactions', data)
            }).catch(error => {
                API.handleError(error)
            })
        },

        setCategory({ commit }, data) {
            API.setCategory(data).then(res => {
                // console.log(res.data)
                res.data.map(rec => {
                    commit('updateTransactionCategory', rec)
                })
            }).catch(error => API.handleError(error))
        },

        addCategory({ commit }, data) {
            return API.addCategory(data).catch(err => API.handleError(err))
        },

        updateCategory({ commit }, data) {
            return API.updateCategory(data._id, {
                category: data.category,
                master: data.master,
                visible: data.visible
            }).catch(err => API.handleError(err))
        },

        moveTo({ commit }, data) {
            API.moveTo(data).then(res => {
                res.data.map(modified => {
                    commit('updateTransactionFrom', modified.record)
                    modified.accounts.map(account => commit('updateAccount', account))
                })
            }).catch(error => API.handleError(error))
        },

        // Budget
        getCurrentBudget({ commit }) {
            this.commit('app/setLinearProgress', true)
            API.getCurrentBudget().then(res => {
                this.commit('app/setLinearProgress', false)
                commit('setCurrentBudget', res.data)
            }).catch(error => API.handleError(error))
        },

        getBudget({ commit }, month) {
            this.commit('app/setLinearProgress', true)
            return API.getBudget(month).then(res => {
                this.commit('app/setLinearProgress', false)
                commit('setBudget', res.data)
                return Promise.resolve(res.data)
            }).catch(error => {
                API.handleError(error)
                return Promise.reject(error)
            })
        },

        addBudget({ commit }, data) {
            // console.log('addBudget', data)
            API.addBudget(data).then(res => {
                // console.log(res.data)
                // commit('setNewBudget', res.data)
            }).catch(error => API.handleError(error))
        },

        deleteBudgetMove({ commit }, id) {
            commit('deleteBudgetMove', id)
            API.deleteBudget(id).then(res => {
                console.log(res)
            }).catch(err => API.handleError(err))
        },

        updateBudget({ commit }, data) {
            return API.updateBudget(data).then(res => {
                // console.log('update budget api',res.data)
                commit('setNewBudget', res.data)
            }).catch(error => API.handleError(error))
        },

        moveBudget({ commit }, data) {
            console.log('moveBudget', data)
            API.moveBudget(data).then(res => {
                // console.log(res.data)
                commit('setNewBudget', res.data)
            }).catch(err => API.handleError(err))
        },

        // Categories
        getCategories({ commit }) {
            API.getCategories().then(res => {
                commit('setCategories', res.data)
            }).catch(error => API.handleError(error))
        },

        // Payees
        getPayees({ commit }) {
            API.getPayees().then(res => {
                commit('setPayees', res.data)
            }).catch(error => API.handleError(error))
        },

        getPayeeList({ commit }) {
            API.getPayeeList().then(res => commit('setPayeeList', res.data))
                .catch(error => API.handleError(error))
        },

        updatePayee({ commit }, data) {
            API.updatePayee(data.id, { ...data, id: undefined }).then(res => { /** TODO */ })
                .catch(error => API.handleError(error))
        },

        updatePayees({ commit }, data) {
            API.updatePayees(data).then(res => { /** TODO */ })
        },

        mergePayees({ commit }, data) {
            API.mergePayees(data).then(res => {/** TODO */ })
                .catch(error => API.handleError(error))
        },

        deletePayee({ commit }, payeeId) {
            API.deletePayee(payeeId).then(res => commit('removePayees', [payeeId]))
                .catch(error => API.handleError(error))
        },

        deletePayees({ commit }, payees) {
            API.deletePayees(payees).then(res => commit('removePayees', payees))
        },


    },

    mutations: {
        setRates(state, data) { state.rates = data },
        setAccounts(state, data) { state.accounts = data },
        addAccount(state, account) {
            state.accounts.push(account)
        },
        setAccount(state, account) {
            state.accounts = state.accounts.map(acc => {
                if (acc._id == account._id) return account
                else return acc
            })
        },
        delAccount(state, accoundId) {
            state.accounts = state.accounts.filter(item => item._id != accoundId)
        },
        updateAccount(state, data) {
            for (var acc of state.accounts) {
                if (acc.account === data.account) {
                    acc.balance = data.balance
                    break
                }
            }
        },

        setTransactions(state, data) { state.transactions = data },
        addTransaction(state, data) { state.transactions.unshift(data) },
        removeTransaction(state, id) { state.transactions = state.transactions.filter(item => item._id !== id) },
        clearTransactions(state, data) {
            state.transactions.map(item => {
                if (data.ids.includes(item._id)) item.cleared = data.clear
            })
        },
        flagTransactions(state, data) {
            state.transactions.map(item => {
                if (data.ids.includes(item._id)) item.flag = data.flag
            })
        },
        updateTransactionCategory(state, data) {
            let trans = state.transactions.find(item => item._id === data._id)
            if (trans) trans.categoryId = data.categoryId
        },
        updateTransactionFrom(state, data) {
            let record = state.transactions.find(item => item._id === data._id)
            if (record) {
                if (record.from._id !== data.from._id)
                    state.transactions = state.transactions.filter(item => item._id !== data._id)
            }
        },
        updateTransactions(state, trans) {
            trans.map(item => {
                let record = state.transactions.find(t => t._id === item._id)
                if (record) {
                    record.cleared = item.cleared
                }
            })
        },
        setTransQuery(state, data) {
            state.query = data.result
            if(data.trend)state.trendQuery = data.trend
            state.lastRevenue = data.revenue
        },

        setCurrentBudget(state, data) {
            state.currentBudget = data
        },
        setBudget(state, data) { state.budget = data },
        updateBudget(state, data) {
            let income = state.budget.find(item => item._id === 'Income');
            let readyToAssign = income.subs.find(item => item.category === 'Ready to Assign');
            state.budget.map(master => {
                master.subs.map(cat => {
                    if (cat._id === data.id) {
                        // cat.assign += data.assign BUG: !!! because scope is used in q-input, value is already assigned
                        cat.moves.push({
                            date: data.month,
                            from: data.assign > 0 ? "Ready to Assign" : cat.category,
                            to: data.assign > 0 ? cat.category : "Ready to Assign",
                            amount: Math.abs(data.assign)
                        })
                        cat.assign = data.assign
                        master.assign += data.assign
                        readyToAssign.moves.push({
                            date: data.month,
                            from: data.assign > 0 ? cat.category : "Ready to Assign",
                            to: data.assign > 0 ? "Ready to Assign" : cat.category,
                            amount: Math.abs(data.assign)
                        })
                        readyToAssign.assign -= data.assign
                        income.assign -= data.assign
                    }
                })
            })
        },
        deleteBudgetMove(state, id) {
            let income = state.budget.find(item => item._id === 'Income');
            let readyToAssign = income.subs.find(item => item.category === 'Ready to Assign');
            state.budget.map(master => {
                master.subs.map(cat => {
                    cat.moves.map(move => {
                        if (move._id === id) {
                            if (move.from === "Ready to Assign") {
                                cat.assign -= move.amount
                                master.assign -= move.amount
                                readyToAssign.assign += move.amount
                                income.assign += move.amount
                            } else {
                                cat.assign += move.amount
                                master.assign += move.amount
                            }

                        }
                    })
                    cat.moves = cat.moves.filter(item => item._id !== id)
                })
            })
        },
        addBudget(state, data) {
            state.budget.map(master => {
                master.subs.map(sub => {
                    if (sub.category === data.category) {
                        sub.budget.push({ ...data, id: sub._id })
                    }
                })
            })
        },
        setNewBudget(state, data) {
            state.budget.map(master => {
                master.subs.map(sub => {
                    if (sub._id === data.from._id) {
                        sub.assign -= data.amount
                        sub.moves.push({
                            _id: data._id,
                            date: data.date,
                            from: data.from.category,
                            to: data.to.category,
                            amount: data.amount
                        })
                    }
                    else if (sub._id === data.to._id) {
                        sub.assign += data.amount
                        sub.moves.push({
                            _id: data._id,
                            date: data.date,
                            from: data.from.category,
                            to: data.to.category,
                            amount: data.amount
                        })
                    }
                })
            })
        },

        setCategories(state, data) { state.categories = data },


        setPayees(state, data) { state.payees = data },
        setPayeeList(state, data) { state.payeeList = data },
        removePayees(state, ids) {
            state.payees = state.payees.filter(payee => !ids.includes(payee._id))
            state.payeeList = state.payeeList.filter(payee => !ids.includes(payee._id))
        },

        selectPayees(state, ids) {
            for (let payee of state.payees) {
                payee.selected = ids.includes(payee._id)
            }
        }

    },

    getters: {

        // Accounts
        allAccountsSum: state => {
            return Number(state.accounts.reduce((total, acc) => {
                const balance = acc.currency && acc.currency != 'LEK' && state.rates[acc.currency] && state.rates[acc.currency] > 0 ?
                    state.rates[acc.currency] * acc.balance : acc.balance
                return total + balance
            }, 0).toFixed(0)).toLocaleString('de-DE')
        },
        budgetAccounts: state => {
            const accounts = state.accounts.filter(item => item.type === 'budget')
            return {
                accounts: accounts.map(item => {
                    const balance = Number(item.balance)
                    return {
                        ...item,
                        balance: Number(balance.toFixed(item.currency == 'LEK' ? 0 : 2)),
                        rawBalance: balance
                    } // TODO: it does not work with other locales
                }),
                sum: Number(accounts.reduce((total, acc) => {
                    const balance = acc.currency && acc.currency != 'LEK' && state.rates[acc.currency] && state.rates[acc.currency] > 0 ?
                        state.rates[acc.currency] * acc.balance : acc.balance
                    return total + balance
                }, 0).toFixed(0)).toLocaleString('de-DE')
            }
        },
        loanAccounts: state => {
            const accounts = state.accounts.filter(item => item.type === 'loan')
            return {
                accounts: accounts.map(item => {
                    const balance = Number(item.balance)
                    return { ...item, balance: balance.toLocaleString('de-DE') } // TODO: it does not work with other locales
                }),
                sum: accounts.reduce((total, acc) => {
                    return total + acc.balance
                }, 0).toFixed(2)//.toLocaleString('de-DE')
            }
        },
        trackAccounts: state => {
            const accounts = state.accounts.filter(item => item.type === 'track')
            return {
                accounts: accounts.map(item => {
                    const balance = Number(item.balance)
                    return { ...item, balance: balance.toLocaleString('de-DE') } // TODO: it does not work with other locales
                }),
                sum: accounts.reduce((total, acc) => {
                    return total + acc.balance
                }, 0).toFixed(2)//toLocaleString('de-DE')
            }
        },
        closedAccounts: state => {
            const accounts = state.accounts.filter(item => item.type === 'closed')
            return {
                accounts,
                sum: accounts.reduce((total, acc) => {
                    return total + acc.balance
                }, 0)
            }
        },
        receivableAccounts: state => {
            const accounts = state.accounts.filter(item => item.type === 'receivable')
            return {
                accounts,
                sum: accounts.reduce((total, acc) => {
                    return total + acc.balance
                }, 0)
            }
        },
        liabilityAccounts: state => {
            const accounts = state.accounts.filter(item => item.type === 'liability')
            return {
                accounts: accounts.map(item => {
                    const balance = Number(item.balance)
                    return {
                        ...item,
                        balance: Number(balance.toFixed(item.currency == 'LEK' ? 0 : 2)),
                        rawBalance: balance
                    } // TODO: it does not work with other locales
                }),
                sum: Number(accounts.reduce((total, acc) => {
                    const balance = acc.currency && acc.currency != 'LEK' && state.rates[acc.currency] && state.rates[acc.currency] > 0 ?
                        state.rates[acc.currency] * acc.balance : acc.balance
                    return total + balance
                }, 0).toFixed(0)).toLocaleString('de-DE')
            }
        },

        // Transactions
        clearedBalance: state => {
            let trans = state.transactions.filter(item => item.cleared === 'C')
            if (trans.length === 0) return 0
            return trans.reduce((total, item) => {
                return total + item.inflow - item.outflow
            }, 0)
        },
        unclearedBalance: state => {
            let trans = state.transactions.filter(item => item.cleared === 'U')
            if (trans.length === 0) return 0
            return trans.reduce((total, item) => {
                return total + item.inflow - item.outflow
            }, 0)
        },

        // Budget
        getBudget: state => {
            let result = []
            state.budget.map(master => {

                let budget = master.budget
                let id = budget[0] ? budget[0]._id : null
                let inherit = budget.reduce((total, item) => { return total + item.inherit }, 0)
                let budgeted = budget.reduce((total, item) => {
                    return total + item.budgeted
                }, 0)
                let spent = master.trans.reduce((total, item) => {
                    return total + item.inflow - item.outflow
                }, 0)
                result.push({
                    id, type: 'master',
                    category: master.category, budgeted, spent, balance: inherit + budgeted + spent,
                    trans: master.trans
                })

                master.subs.map(sub => {
                    let budget = sub.budget
                    let id = budget[0] ? budget[0]._id : null
                    let inherit = budget.reduce((total, item) => { return total + item.inherit }, 0)
                    let budgeted = budget.reduce((total, item) => { return total + item.budgeted }, 0)
                    let spent = sub.trans.reduce((total, item) => {
                        return total + item.inflow - item.outflow
                    }, 0)
                    let res = budget.length > 0 ? budget[0] : {}
                    result.push({
                        ...res,
                        id, type: 'sub',
                        category: sub.category, budgeted, spent, balance: inherit + budgeted + spent, inherit,
                        trans: sub.trans
                    })
                })
            })
            return result
        },

        // Payees
        getPayees: (state) => (account) => {
            let accounts = state.accounts.filter(item => { return item.account !== account })
            let payees = state.payees.map(item => {
                return item
            })
            accounts.map(item => {
                payees.push({ payee: `Transfer : ${item.account}`, _id: item._id })
            })
            // console.log('getPayees',payees)
            return payees || []
        },
        getLastCategory: (state) => (payee) => {
            let match = state.payees.find(item => item.payee === payee)
            if (match && match.lastCategory) {
                for (let cat of state.categories) {
                    for (let sub of cat.subs) {
                        if (sub.category === match.lastCategory)
                            return {
                                masterCategory: cat.category,
                                subCategory: sub.category,
                                category: `${cat.category} : ${sub.category}`
                            }
                    }
                }
            }
            return null
        }

    }
}

export default db