<template>
    <q-card class="">
        <q-card-section>
            <div class="text-h3">{{ accObj._id ? 'Edit' : 'Create' }} Account</div>
        </q-card-section>

        <q-separator color="white" />

        <q-card-section>
            <q-form class="q-gutter-sm">
                <q-input outlined label="Account Name" v-model="accObj.account" />
                <q-select outlined :options="['budget', 'loan', 'track', 'closed', 'payable', 'liability']" label="Type" v-model="accObj.type" />
                <q-select outlined :options="['LEK', 'USD', 'EUR', 'GBP']" label="Currency" v-model="accObj.currency" />
                <div>Balance: {{ account.balance }} </div>
                <div>Total Trans: {{ account.count }}</div>
            </q-form>
            
        </q-card-section>

        <q-separator color="white" />

        <q-card-actions>
            <q-btn outline @click="$emit('dismiss')">
                Cancel
            </q-btn>
            <q-btn color="primary" @click="edit">
                {{ accObj._id? 'Save' : 'Create' }}
            </q-btn>
            <q-btn v-if="accObj._id" icon="delete" color="red" class="float-right" @click="delAcc" />
        </q-card-actions>
    </q-card>
</template>

<script>
export default {
    data() {
        return {
            accObj: { type: 'budget', currency: 'LEK' }
        }
    },
    
    props: {
        account: Object,
    },
    
    mounted() {
        if(!this.isNew)this.accObj = this.account
    },

    methods: {
        edit(){
            if(this.accObj._id){
                this.$store.dispatch('db/editAccount', this.accObj)
            }
            else {
                this.$store.dispatch('db/addAccount', this.accObj)
            }
            this.$emit('dismiss')
        },
        delAcc() {
            let canDel = confirm(`Are you sure you want to delete account: ${this.accObj.account}?`)
            if(canDel)this.$store.dispatch('db/deleteAccount', this.accObj._id)
            this.$emit('dismiss')
        }
    }
}
</script>

<style></style>