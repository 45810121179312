<template>
  <div class="q-pa-md">
    <div class="text-h4 q-mb-md">{{ title }}</div>
    <q-btn-toggle
        v-model="timeframe"
        push spread no-caps
        toggle-color="primary"
        :options="toolbar"
        class="q-mb-sm"
        @click="setRange"
    />
    
    <div class="row">
        <q-date class="" v-model="model" range mask="YYYY-MM-DD" @range-end="rangeSelected" />
        <slot></slot>
        <div class="absolute-bottom-right q-mb-md q-mr-md q-gutter-sm">
            <q-btn label="Cancel" @click="$emit('dismiss')"/>
            <q-btn label="Apply" color="accent" @click="$emit('dismiss', model)"/>
        </div>
    </div>
  </div>
  
</template>

<script>
import { date } from 'quasar'
export default {
    data(){
        return {
            model: {from:'', to:''},
            timeframe:'',
            toolbar: [
                {label:'This Month', value:'month'},
                {label:'Latest 3 Months', value:'quarter'},
                {label:'Half Year', value:'half'},
                {label:'This Year', value:'year'},
                {label:'Last Year', value:'lastYear'},
                {label:'All Dates', value:'all'},
            ]
        }
    },
    props: {
        title:String,
        range:Object,
        extra:Array
    },

    computed:{
        
    },

    methods: {
        setRange(){
            const currMonthEnd = date.addToDate(new Date(), { months: 1 }).setDate(1)
            switch(this.timeframe){
                case 'month': {
                    let start = new Date().setDate(1)
                    this.model.from = date.formatDate(start, 'YYYY-MM-DD')
                    this.model.to = date.formatDate(currMonthEnd, "YYYY-MM-DD")
                    break
                }
                case 'quarter': {
                    let start = new Date().setDate(1)
                    this.model.from = date.formatDate(date.addToDate(start, { months: -2 }, "YYYY-MM-DD"))
                    this.model.to = date.formatDate(currMonthEnd, "YYYY-MM-DD")
                    break
                }
                case 'half': {
                    let start = new Date().setDate(1)
                    this.model.from = date.formatDate(date.addToDate(start, { months: -5 }, "YYYY-MM-DD"))
                    this.model.to = date.formatDate(currMonthEnd, "YYYY-MM-DD")
                    break
                }
                case 'year':{
                    let start = new Date(); start.setMonth(0); start.setDate(1)
                    this.model.from = date.formatDate(start, "YYYY-MM-DD")
                    this.model.to = date.formatDate(currMonthEnd, "YYYY-MM-DD")
                    break
                }
                case 'lastYear':{
                    let start = new Date(); start.setMonth(0); start.setDate(1)
                    this.model.to = date.formatDate(start, "YYYY-MM-DD")
                    start = date.subtractFromDate(start, { years: 1 })
                    this.model.from = date.formatDate(start, "YYYY-MM-DD")
                    break
                }
                case 'all':{
                    let start = new Date(); start.setDate(1); start.setMonth(0);
                    this.model.to = date.formatDate(currMonthEnd, "YYYY-MM-DD")
                    start = date.subtractFromDate(start, { years: 10 })
                    this.model.from = date.formatDate(start, "YYYY-MM-DD")
                    break
                }
            }
            // console.log(this.model.from, this.model.to)
            this.$emit('dismiss', this.model)
            // this.$emit('update:range', this.model)
        },

        rangeSelected(range){
            const start = date.buildDate(range.from)
            const end = date.buildDate(range.to)
            this.model.from = date.formatDate(start, "YYYY-MM-DD")
            this.model.to = date.formatDate(end, "YYYY-MM-DD")
            this.$emit('dismiss', this.model)
            // this.$emit('update:range', this.model)
        }
    },

    mounted(){
        this.model = this.range ? this.range : {
            from: date.formatDate(date.addToDate(Date.now(), {months:-1}), 'YYYY-MM-DD'),
            to: date.formatDate(date.addToDate(Date.now(), { months: 1 }).setDate(1), 'YYYY-MM-DD'),
        }
    },
    beforeUnmount(){
        this.$emit('update:range', this.model)
    }
}
</script>

<style>

</style>