<template>

    <div>
        <q-toolbar class="bg-accent text-white">
            <q-toolbar-title>Add Transaction</q-toolbar-title>
            <q-btn icon="save" flat rounded @click="save"/>
        </q-toolbar>

        <div class="q-pa-md" :class="type === 'expense' ? 'bg-orange-5':'bg-green-5'">
        <q-btn-toggle v-model="type" spread class="bg-white"
            :options="[
                {label: 'Expense', value:'expense'},
                {label: 'Income', value:'income'}
            ]"
        />
        <q-item>
            <!-- <q-input v-model="amount" placeholder="... Lek" autofocus type="Number" flat class="text-h2" item-aligned color="primary"/> -->
            <input v-model="amount" placeholder="...Lek" autofocus type="number" inputmode="numeric"
                class="text-h5 q-mx-auto text-center" style="width:90%"/>
        </q-item>
        </div>

        <!-- Section 1 -->
        <q-card class="q-mt-md">
        <q-list separator>
            <q-item v-for="item in section1" :key="item.name" clickable @click="item.click">
                <q-item-section avatar>
                    <q-icon :name="item.icon"/>
                </q-item-section>            
                <q-section>
                    <q-item-label caption="record[item.name]">
                        <span v-if="!record[item.name]">Choose</span>
                        {{ item.label }}
                    </q-item-label>
                    <q-item-label v-if="record[item.name]">{{ record[item.name] }}</q-item-label>
                </q-section>
                <q-space/>
                <q-item-section side>
                    <q-icon name="navigate_next"/>
                </q-item-section>
            </q-item>
            <!-- Date set -->
            <q-item>
                <q-item-section avatar>
                    <q-icon name="event"/>
                </q-item-section>
                <q-section>
                    <q-item-label caption>Date</q-item-label>
                    <q-item-label>{{ formatDate(record.date) }}</q-item-label>
                </q-section>
                <q-popup-proxy>
                    <q-date v-model="record.date" mask="YYYY-MM-DD">
                        <div class="row items-center justify-end">
                            <q-btn v-close-popup label="Close" color="primary" flat />
                        </div>
                    </q-date>
                </q-popup-proxy>
            </q-item>
        </q-list>
        </q-card>

        <!-- Section 2 -->
        <q-card class="q-mt-md">
        <q-list>
            <!-- Cleared switch -->
            <q-item>
                <q-item-section avatar>
                    <q-icon name="done" :color="record.cleared && 'positive'"/>
                </q-item-section>
                <q-item-section>Cleared</q-item-section>
                <q-space/>
                <q-toggle v-model="cleared"/>
            </q-item>
            <!-- Flag select -->
            <q-item>
                <q-item-section avatar>
                    <q-icon name="bookmark" :color="record.flag"/>
                </q-item-section>
                <q-item-section>
                    <q-item-label caption>Flag</q-item-label>
                    <q-item-label>{{ record['flag'] }}</q-item-label>
                </q-item-section>
                <q-space/>
                <q-item-section side><q-icon name="navigate_next"/></q-item-section>
            </q-item>
            <!-- Memo input -->
            <q-item>
                <q-item-section avatar>
                    <q-icon name="note" />
                </q-item-section>
                <q-item-section>
                    <q-input v-model="record.memo" placeholder="Enter a memo..." dense/>
                </q-item-section>
                <q-space/>
                <q-item-section side><q-icon name="navigate_next"/></q-item-section>
            </q-item>
        </q-list>
        </q-card>

        <q-space/>

        <!-- Save button -->
        <div class="float-right q-mt-xl">
            <!-- <q-fab label="Save" icon="save" color="accent" @click="save">
            </q-fab> -->
            <q-btn label="Save" icon="save" color="accent" @click="save"/>
        </div>
    </div>
    
    <q-drawer v-model="showDrawer" :width="maxWidth" side="right" no-swipe-open>
        <q-toolbar class="bg-accent text-white">
            <q-btn @click="showDrawer = false" label="Cancel" icon="chevron_left" size="sm" flat/>
            <q-toolbar-title>Select {{ drawerContent }}</q-toolbar-title>
        </q-toolbar>

        <div class="q-px-xl">
            <PayeeList v-if="drawerContent === 'payee'" :account="account" @setPayee="setPayee"/>
            <CategoryList v-if="drawerContent === 'category'" :selected="record['subCategory']" @setCategory="setCategory"/>
            <AccountList v-if="drawerContent === 'account'" :selected="record.account" @setAccount="setAccount"/>
        </div>
        
    </q-drawer>

    <!-- Alert Dialog -->
    <q-dialog v-model="alert">
        <q-card>
            <q-card-section>
                <div class="text-h6">{{ alertTitle }}</div>
            </q-card-section>
            <q-card-section>
                {{ alertMessage }}
            </q-card-section>
            <q-card-actions align="right">
                <q-btn flat label="OK" color="primary" v-close-popup />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { date } from 'quasar'
import { mapGetters } from 'vuex'
import PayeeList from '@/components/PayeeList.vue'
import CategoryList from '@/components/CategoryList.vue';
import AccountList from '../components/AccountList.vue';
export default {
    name: "TransactionMobile",
    data() {
        return {
            type: "expense",
            amount: '', cleared:false,
            section1: [
                { label: "Payee", name: "payee", icon: "sync_alt", click:()=>{
                    this.drawerContent = 'payee'; this.showDrawer = true;
                }},
                { label: "Category", name: "subCategory", icon: "category", click:() => {
                    this.drawerContent = 'category'; this.showDrawer = true;
                } },
                { label: "Account", name: "account", icon: "account_balance", click: () => {
                    this.drawerContent = 'account'; this.showDrawer = true;
                }},
            ],
            record: {
                account:'',
                date:new Date().toISOString(),
                subCategory:'',
                memo: "",
                cleared: 'U',
            },
            showDrawer: false, drawerContent:'', 
            alert:false, alertTitle:'', alertMessage:'',
        };
    },
    computed: {
        ...mapGetters({
            getLastCategory: 'db/getLastCategory'
        }),
        account(){
            return localStorage.getItem('lastAccount') || 'Portofol'
        },
        maxWidth(){
            return window.innerWidth
        }
    },

    methods: {
        setPayee(payee){
            this.record.payee = payee
            let lastCategory = this.getLastCategory(payee)
            if(lastCategory){
                this.record = {...this.record, ...lastCategory}
            }
            this.showDrawer = false
        },
        setCategory(item){
            this.record = {
                ...this.record,
                ...item
            }
            this.showDrawer = false
        },
        setAccount(account){
            this.record.account = account
            localStorage.setItem('lastAccount', account)
            this.showDrawer = false
        },

        save(){
            this.amount = parseFloat(this.amount)
            this.record.inflow = this.type === 'expense' ? 0 : this.amount
            this.record.outflow = this.type === 'expense' ? this.amount : 0
            this.record.cleared = this.cleared ? 'C' : 'U'
            this.$store.dispatch('db/addTransaction', this.record).then(res => {
                console.log('got Response', res)
                if(res.record){
                    this.alertTitle = "Transaction Saved!"
                    this.alertMessage = "Transaction saved successfully"
                    this.alert = true
                }
                
                this.amount = ''; this.cleared = false; this.drawerContent = ''
                delete this.record.payee
                delete this.record.category
                delete this.record.masterCategory
                delete this.record.subCategory
                delete this.record.memo
                delete this.record.inflow
                delete this.record.outflow
            }).catch(err => {
                console.log('gotError', err)
                this.alertTitle = "There was some error"
                this.alertMessage = err
                this.alert = true
            })
        },

        // Utilities
        formatDate(dateString){
            return date.formatDate(new Date(dateString), 'DD/MM/YYYY')
        }
    },

    mounted() {
        localStorage.setItem("last-mobile-tab", "transaction");
        this.record.account = localStorage.getItem('lastAccount')
    },
    components: { PayeeList, CategoryList, AccountList }
}
</script>

<style>

</style>