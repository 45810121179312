let lastPage = localStorage.getItem('lastPage') || 'ledger'
let lastAccountId = localStorage.getItem('lastAccountId')

const app = {

    namespaced: true,

    state: {
        lastPage,
        lastAccountId,
        linearProgress: false,
    },

    mutations: {
        setLastPage(state, page){
            localStorage.setItem('lastPage', page)
            state.lastPage = page
        },
        setLastAccount(state, accountId){
            if(accountId)localStorage.setItem('lastAccountId', accountId)
            state.lastAccountId = accountId
        },
        setLinearProgress(state, progress){state.linearProgress = progress},
        lastPage(state, page){
            state.lastPage = page
            localStorage.setItem('lastPage', page)
        }
    },
    getters: {
        lastAccount: (state, getters, rootState) => {
            if(!state.lastAccountId)return '*'
            let accountId = state.lastAccountId
            let account = rootState.db.accounts.find(item => item._id === accountId)
            // console.log('lastAccount', accountId, account)
            if(!account)return ''
            return account.account
            // return 'Test'
        }
    }
}

export default app