<template>
  <div>
    <q-input v-model="category" placeholder="Search Categories..." @keyup.enter="setCategory(filterCategories[0])" autofocus>
        <template v-slot:prepend>
            <q-icon name="search" />
        </template>
    </q-input>
    <q-list separator>
        <q-item clickable v-for="item in filterCategories" :key="item.category" @click="setCategory(item)">
            <q-item-section>{{ item.label }}</q-item-section>
            <q-item-section side v-if="item.type === 'sub'">
                {{ item.balance }}
                <q-icon name="done" color="primary" v-if="item.subCategory === selected"/>
            </q-item-section>
        </q-item>
    </q-list>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
    name:"CategoryList",

    data(){
        return {
            category:''
        }
    },

    props: {
        selected: String
    },

    computed:{
        ...mapState({
            categories: state => state.db.categories
        }),
        ...mapGetters({
            getPayees: 'db/getPayees',
        }),

        filterCategories(){
            let result = []
            this.categories.map(master => {
                result.push({
                    category:master.category, type:'master', label:master.category
                })
                master.subs.map(sub => {
                    result.push({
                        _id:sub._id,
                        category:`${master.category} : ${sub.category}`, type:'sub', 
                        label:`${sub.category}(${master.category})`,
                        masterCategory:master.category, subCategory:sub.category,
                        balance:sub.balance
                    })
                })
            })
            return result.filter(item => item.category.toLowerCase().includes(this.category.toLowerCase()))
        }
    },

    methods: {
        setCategory(item){
            if(item.type === 'master'){
                alert('This is a master account! Choose a sub!')
                return
            }
            this.$emit('setCategory',item)
        }
    }
}
</script>

<style>

</style>