<template>
  <div class="q-pa-md">
    <div class="text-h4">Categories</div>
    <q-toolbar class="q-gutter-lg">
        <q-btn label="Select All" color="primary" flat @click="selectAllNone(true)"/>
        <q-btn label="Select None" color="primary" flat @click="selectAllNone(false)"/>
    </q-toolbar>
    <q-list separator>
        <div v-for="item in cats" :key="item.category">
            <q-item clickable  >
                <q-item-section avatar>
                    <q-checkbox v-model="item.selected" @click="selectMaster(item.selected, item._id)"/>
                </q-item-section>
                <q-item-section>{{ item.category }}</q-item-section>
            </q-item>
            <q-item v-for="sub in item.subs" :key="sub.category" class="q-ml-xl" >
                <q-item-section avatar>
                    <q-checkbox v-model="sub.selected" @click="selectSub(sub.selected, sub._id)"/>
                </q-item-section>
                <q-item-section>{{ sub.category }}</q-item-section>
            </q-item>
        </div>  
    </q-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name:"CategoryList",

    data(){
        return {
            selectedCats:[], cats:[]
        }
    },

    props: {
        selected: Array
    },

    computed:{
        ...mapState({
            categories: state => state.db.categories
        }),
        
        selectedCategories(){
            let ids = []
            this.cats.map(master => {
                master.subs.map(sub => {
                    if(sub.selected === true)ids.push(sub._id)
                })
            })
            return ids
        }
    },

    methods: {
        selectSub(value, id){
            this.cats.map(master => {
                let state = master.subs.filter(item => item._id !== id).map(item => item.selected)
                master.subs.map(sub => {
                    if(sub._id === id){
                        if(value === true)master.selected = true
                        else if(!state.includes(true))master.selected = false
                    }
                })
            })
            // this.$emit('update:selected', this.selectedCategories)
        },
        selectMaster(value, id){
            let master = this.cats.find(item => item._id === id)
            let state = master.subs.map(item => item.selected)
            if(state.includes(true))master.subs = master.subs.map(item => {return {...item, selected:false}})
            else master.subs = master.subs.map(item => {return {...item, selected:true}})
            // this.$emit('update:selected', this.selectedCategories)
        },
        selectAllNone(all){
            this.cats = this.cats.map(master => {
                master.subs = master.subs.map(sub => {return{...sub, selected:all}})
                return {...master, selected:all}
            })
            // this.$emit('update:selected', this.selectedCategories)
        },

        isSelected(id){
            return this.selectedCats.includes(id)
        },
        isMasterSelected(id){
            let master = this.categories.find(item => item._id === id)
            for(const sub of master.subs){
                if(this.selectedCats.includes(sub._id))return true
            }
            return false
        }
    },

    mounted(){
        this.cats = this.categories.map(master => {
            master.subs = master.subs.map(sub => {
                let selected = this.selected.includes(sub._id) || this.selected.length === 0
                if(selected)master.selected = true
                return {...sub, selected}
            })
            return {
                ...master
            }
        })
    },
    beforeUnmount(){
        this.$emit('update:selected', this.selectedCategories)
    }
}
</script>

<style>

</style>