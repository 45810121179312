import { Utils } from 'chart.js'
import randomColor from 'randomcolor'
import date from 'quasar/src/utils/date.js';
import store from '@/store'

class ChartData {



    masterExpenses(data) {
        return {
            type: 'doughnut',
            data: {
                labels: data.map(item => item._id),
                datasets: [
                    {
                        label: 'Expenses',
                        data: data.map(item => Math.abs(item.activity)),
                        backgroundColor: data.map((item, index) => {
                            let ind = (index + 1) % colors.length
                            return colors[Math.min(ind, colors.length - 1)]
                        })
                    }
                ]
            }
        }
    }

    masterExpensesTrend(data, labels, click) {
        // let masterCats = data.map(item => item.name)
        // for(const item of data){
        //     for(const cat of item.masters){
        //         if(!masterCats.includes(cat.master))masterCats.push(cat.master)
        //     }
        // }

        return {
            // series: masterCats.map(cat => {
            //     return {
            //         name: cat,
            //         data: data.map(item => {
            //             let match = item.masters.find(m => m.master == cat)
            //             let amount = match ? match.activity * -1 : 0
            //             return parseInt(amount)
            //         })
            //     }
            // }),
            series: data,
            options: {
                chart: {
                    type: 'bar',
                    stacked: true,
                    toolbar: { show: true },
                    zoom: { enabled: true },
                    events: {
                        dataPointSelection: click || function (event, chartContext, config) {
                            console.log(config)
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        dataLabels: {
                            total: {
                                enabled: true,
                                offsetY: -5,
                                formatter: function (value, config) {
                                    return value < 1000 ? value : (value / 1000).toFixed(0) + 'k'
                                }
                            }
                        },
                    }
                },
                dataLabels: {
                    enabled: false,
                },
                xaxis: {
                    // categories: data.map(item => item.dateKey)
                    categories: labels
                },

            }
        }
        // return {
        //     type: 'bar',
        //     data: {
        //         labels: data.map(item => item.dateKey),
        //         // datasets: [
        //         //     {
        //         //         label: 'Months',
        //         //         data: data.map(item => Math.abs(item.activity)),
        //         //         backgroundColor: data.map((item, index) => {
        //         //             let ind = (index + 1) % colors.length
        //         //             return colors[Math.min(ind, colors.length - 1)]
        //         //         })
        //         //     }
        //         // ]
        //         datasets: data.map(item => {
        //             return {
        //                 label: item.dateKey,
        //                 data: item.masters.map(master => master.activity * -1),
        //                 backgroundColor: data.map((master, index) => {
        //                     let ind = (index + 1) % colors.length
        //                     return colors[Math.min(ind, colors.length - 1)]
        //                 })
        //             }
        //         })
        //     }
        // }
    }

    netWorth(income, expense, lastRevenue, currentBudget) {

        let result = { type: 'line' }

        let data = [...income, ...expense]
        data.sort((a, b) => { return new Date(a.date) - new Date(b.date) })
        let xLabels = data.map(item => item._id)
        xLabels = [...new Set(xLabels)]

        const currMonth = date.formatDate(new Date(), 'MM/YY')
        let expectedExpense = currentBudget.reduce((total, item) => {
            if (item._id == 'Income') return total
            return total + item.assign + item.inherit
        }, 0)
        let expectedIncome = income.reduce((total, item) => {
            return total + item.activity
        }, 0) / (income.length - 1)

        let revenue = []
        let totalRevenue = lastRevenue
        for (const label of xLabels) {
            const inc = income.find(item => item._id == label) || { activity: 0 }
            const exp = expense.find(item => item._id == label) || { activity: 0 }
            totalRevenue += inc.activity + exp.activity
            revenue.push({
                x: label,
                y: totalRevenue,
                goals: label == currMonth ? [{
                    name: 'Forecasted',
                    value: totalRevenue + expectedIncome - expectedExpense,
                    strokeColor: 'orange',
                    strokeDashArray: 1,
                    shape: 'circle'
                }] : undefined
            })
        }

        result.series = [
            {
                name: 'Income',
                type: 'column',
                data: xLabels.map(label => {
                    const inc = income.find(item => item._id == label)
                    return {
                        x: label,
                        y: inc?.activity || 0,
                        goals: label == currMonth ?
                            [{
                                name: 'Expected',
                                value: expectedIncome,
                                strokeColor: 'green'
                            }] : undefined
                    }
                })
            },
            {
                name: 'Expense', type: 'column', data: xLabels.map(label => {
                    const exp = expense.find(item => item._id == label)
                    return {
                        x: label,
                        y: (exp?.activity || 0) * -1,
                        goals: label == currMonth ?
                            [{
                                name: 'Expected',
                                value: expectedExpense,
                                strokeColor: 'red'
                            }] : undefined
                    }
                })
            },
            {
                name: 'Revenue', type: 'area', data: revenue,
            }
        ]

        if (xLabels.includes(currMonth)) {
            totalRevenue = lastRevenue
            result.series.push({
                name: 'Forecast',
                type: 'line',
                data: xLabels.map((label, index) => {
                    const inc = income.find(item => item._id == label) || { activity: 0 }
                    const exp = expense.find(item => item._id == label) || { activity: 0 }
                    totalRevenue += inc.activity + exp.activity
                    const y = index == xLabels.length - 2 ? totalRevenue : 
                        label == currMonth ? totalRevenue + expectedIncome - expectedExpense :
                        null
                    return { x: label, y }
                })
            })
            console.log(result.series)
        }

        const revMin = Math.min(...revenue.map(item => item.y))
        const revMax = Math.max(...revenue.map(item => item.y))
        const revColorStop = [
            { offset: 0, color: revMax < 0 ? 'red' : 'green' },
            { offset: 100, color: revMin < 0 ? 'red' : 'yellow' }
        ]
        result.options = this.netWorthOptions(xLabels, revColorStop)
        return result
    }

    budgetChart(label, data) {
        return {
            data: {
                labels: data.map(item => item.label),
                datasets: [
                    {
                        label,
                        data: data.map(item => item.value),
                        backgroundColor: data.map((item, index) => {
                            let ind = (index + 1) % colors.length
                            return colors[Math.min(ind, colors.length - 1)]
                        })
                    }
                ]
            }
        }
    }

    groupMonth(arr) {
        arr.sort((a, b) => {
            return new Date(a.date) - new Date(b.date)
        })
        let group = {}
        for (const item of arr) {
            let date = new Date(item.date)
            let key = `${date.getMonth()}/${date.getFullYear()}`
            if (group[key]) group[key].push(item)
            else group[key] = [item]
        }
        let result = []
        for (const key in group) {
            let activity = group[key].reduce((total, el) => {
                return total + el.activity
            }, 0)
            let label = date.formatDate(group[key][0].date, 'MM/YY')
            result.push({ _id: label, activity, date: group[key][0].date, trans: group[key] })
        }
        result.sort((a, b) => { return new Date(a.date) - new Date(b.date) })
        return result
    }

    options(text, click) {
        return {
            responsive: true,
            plugins: {
                legend: { display: true },
                title: {
                    display: true, text
                }
            },
            onClick: click
        }
    }

    netWorthOptions(xData, colorStop) {

        return {
            chart: {
                type: 'area', stacked: false
            },
            colors: ['#00FB8F', '#008FFB', '#FEB019', '#FEB019'],
            dataLabels: { enabled: false },
            title: {
                text: 'Analysis', align: 'left', offsetX: 110
            },
            xaxis: { categories: xData },

            yaxis: [
                { seriesName: 'Income', title: { text: 'Income / Expense' }, labels: { formatter: function (val, index) { return val.toLocaleString('de-DE') } } },
                { seriesName: 'Income', show: false, labels: { formatter: function (val, index) { return val.toLocaleString('de-DE') } } },
                { seriesName: 'Revenue', title: { text: 'Revenue' }, opposite: true, labels: { formatter: function (val, index) { return val.toLocaleString('de-DE') } } },
                { seriesName: 'Forecast', show: false, labels: { formatter: function (val, index) { return val.toLocaleString('de-DE') } } }
            ],

            markers: {
                size: 3,
                fillOpacity: 0.8
            },

            tooltip: {
                fixed: {
                    enabled: true,
                    position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                    offsetY: 30,
                    offsetX: 60
                },
            },
            legend: {
                horizontalAlign: 'left',
                offsetX: 40
            },
            stroke: {
                width: [0, 0, 5], curve: 'smooth',
            },
            fill: {
                // opacity: [0.8, 0.8, 0.25],
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1, type: 'vertical',
                    // gradientToColors: ['#00FB8F', '#008FFB', '#FEB019'],
                    opacityFrom: [1, 1, 0.7],
                    opacityTo: [1, 1, 0.1],
                    colorStops: [
                        [
                            { offset: 0, color: '#00FB8F', opacity: 0.8 },
                            { offset: 100, color: '#00FB8F', opacity: 0.8 },
                        ],
                        [
                            { offset: 0, color: '#008FFB', opacity: 0.8 },
                            { offset: 100, color: '#008FFB', opacity: 0.8 },
                        ],
                        // [
                        //     { offset: 0, color: '#FEB019', opacity: 0.7 },
                        //     { offset: 50, color: 'red', opacity: 0.7 },
                        //     // { offset: 100, color: '#FEB019', opacity: 0.7 },
                        // ]
                        colorStop || [
                            { offset: 0, color: '#FEB019', opacity: 0.7 },
                            { offset: 50, color: 'red', opacity: 0.7 },
                            { offset: 100, color: '#FEB019', opacity: 0.7 },
                        ]
                    ]
                }
            }
        }
    }

}

const colors = ['#464653', '#2a9d8f', '#e9c46a', '#f4a261', '#e76f51', '#0081a7', '#00afb9', '#f07167']

export default new ChartData()