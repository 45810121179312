<template>
  <q-page>
    
  </q-page>
</template>

<script>

export default {
    name:'HomePage',

    
}
</script>

<style>

</style>