<template>
    <q-tr>
        <q-td></q-td>
        <q-td>
            <q-icon name="bookmark"/>
        </q-td>
        <!-- Date Select -->
        <q-td>
            <q-input v-model="date" ref="dateInput" 
                @focusin="dateFocusIn" >
                
                <template v-slot:append>
                    <q-btn icon="event" flat @click="showDatePick = !showDatePick" tabindex="0"/>
                </template>

                <q-popup-proxy ref="calendar" @keyup="calendarKeyboard" 
                            fit anchor="bottom left" transition-show="scale" transition-hide="scale">
                            <q-date v-model="date" mask="YYYY-MM-DD" today-btn no-unset
                            @update:model-value="setDate" >
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>

                <!-- <template v-slot:append>
                    <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy ref="calendar" @keyup="calendarKeyboard" 
                            fit anchor="bottom left" transition-show="scale" transition-hide="scale">
                            <q-date v-model="date" mask="YYYY-MM-DD" today-btn no-unset
                            @update:model-value="setDate" >
                                <div class="row items-center justify-end">
                                    <q-btn v-close-popup label="Close" color="primary" flat />
                                </div>
                            </q-date>
                        </q-popup-proxy>
                        
                    </q-icon>
                </template> -->
            </q-input>
            
        </q-td>

        <!-- Payee Select -->
        <q-td>
            <q-input ref="payeeInput" placeholder="payee" v-model="payee"
            @focus="payeeFocusIn"
            @keyup="payeeKeyUp" @blur="setPayee"
            @keydown.escape.prevent="$emit('dismiss')">
                <!-- <q-tooltip no-parent-event v-model="showPayee" class="shadow-6">
                    
                </q-tooltip> -->
                <ToolTip v-model="showPayee" >
                    <div style="max-height: 25rem; min-width:15rem">
                        <div class="row">
                            <h6 class="">Payee</h6>
                            <!-- <q-btn class="" label="Manage payees"></q-btn> -->
                        </div>
                        <hr/>
                        <q-list>
                            <q-item v-for="item in filterPayees" :key="item._id" 
                                :class="payeeId == item._id ? 'bg-primary':''">
                                {{ item.payee }}
                            </q-item>
                        </q-list>
                    </div>
                </ToolTip>
            </q-input>
        </q-td>

        <!-- Category Select -->
        <q-td>
            <q-input ref="categoryInput" placeholder="category" v-model="category"
            @focusin="showCategories = true" @focusout="showCategories = false"
            @keyup.enter="setCategory" @keydown.escape.prevent="$emit('dismiss')">
                <q-tooltip class="shadow-6" no-parent-event v-model="showCategories">
                    <div style="max-height: 25rem; min-width:15rem">
                        <div class="row">
                            <h6 class="">Budget Categories</h6>
                            <!-- <q-btn class="" label="Manage payees"></q-btn> -->
                        </div>
                        <hr/>
                        <q-list>
                            <q-item v-for="item in filterCategories" :key="item._id">
                                <q-item-section>
                                    <q-item-label>{{ item.category }}</q-item-label>                                    
                                </q-item-section>
                                <q-item-section v-if="item.balance" side>
                                    <q-chip v-if="item.balance < 0" color="negative" class="text-white">{{ item.balance }}</q-chip>
                                    <q-item-label v-else>{{ item.balance }}</q-item-label>
                                </q-item-section>
                            </q-item>
                        </q-list>
                    </div>
                </q-tooltip>
            </q-input>
        </q-td>
        <q-td>
            <q-input ref="memoInput" placeholder="memo" v-model="memo" autogrow
            @keydown.escape.prevent="$emit('dismiss')"/>
        </q-td>
        <q-td>
            <q-input ref="outflowInput" placeholder="outflow" v-model="outflow" input-class="text-right" 
            @keyup.enter="save(false)" @keydown.escape.prevent="$emit('dismiss')"/>
        </q-td>
        <q-td>
            <q-input placeholder="inflow" v-model="inflow" input-class="text-right"
            @keyup.enter="save(false)" @keydown.escape.prevent="$emit('dismiss')"/>
        </q-td>
        <q-td>
            <q-icon name="chat_bubble_outline"/>
        </q-td>
    </q-tr>
    <q-tr>
        <q-td class="" colspan="100%">
            <q-toolbar>
                <q-checkbox v-model="showTax" label="add tax?" left-label color="orange"/>
                <q-input v-if="showTax" placeholder="tax percent" v-model="tax" input-class="text-right" style="max-width: 3rem;"
                    @keydown.escape.prevent="$emit('dismiss')"/>
                <q-select v-if="showTax" label="Select tax account" v-model="taxAccount" :options="taxAccounts" class="q-pl-sm"/>
                <q-checkbox v-model="showExchange" label="is exchange?" left-label color="green" class="q-pl-sm"/>
                <q-input v-if="showExchange" placeholder="exchange rate" v-model="xRate" input-class="text-right" 
                    @keydown.escape.prevent="$emit('dismiss')" style="max-width: 5rem;"/>
                <q-space/>   
                <q-btn label="Cancel" @click="$emit('dismiss')"></q-btn>
                <q-btn label="Save" @click="save(true)"></q-btn>
                <q-btn label="Save and Add" @click="save(false)"></q-btn> 
            </q-toolbar>
        </q-td>
    </q-tr>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { date } from 'quasar';
import ToolTip from './ToolTip.vue'
export default {
    name: "TransactionEdit",
    data() {
        return {
            account: "", from:"", to:"", transfer:false,
            date: "",
            payee: "", payeeId:null, payeeIndx: 0,
            category: "", categoryId:"",
            memo: "",
            inflow: "",
            outflow: "",
            cleared: "U",
            flag: "",
            showDatePick: false,
            showPayee: false,
            showCategories: false,
            showFlags: true,
            showTax: false, tax: 0, taxAccount: null,
            showExchange: false, xRate: 0
        };
    },
    props: {
        transData: Object
    },
    computed: {
        ...mapState({
            categories: state => state.db.categories,
            payees: state => state.db.payees,
            accounts: state => state.db.accounts,
        }),
        ...mapGetters({
            getPayees: "db/getPayees",
            lastAccount: 'app/lastAccount',
            liabilityAccounts: 'db/liabilityAccounts'
        }),
        taxAccounts(){
            return this.liabilityAccounts.accounts.map(item => {
                return {
                    label: item.account, value: item._id
                }
            })
        },
        filterPayees() {
            return this.getPayees(this.account).filter(item => item.payee.toLowerCase().includes(this.payee.toLowerCase())).slice(0, 10);
        },
        filterCategories() {
            let result = [];
            let master = this.categories.map(mast => {
                result.push({
                    category: mast.category,
                    _id: mast._id
                });
                mast.subs.map(sub => {
                    result.push({
                        category: `${sub.category}`,
                        _id: sub._id,
                        balance: sub.balance,
                        master: mast.category
                    });
                });
            });
            const filteredCats = this.category && this.category != '' ?
                                result.filter(item => item.category?.toLowerCase().includes(this.category?.toLowerCase())) :
                                result
            return filteredCats.slice(0, 10);
        },
    },
    methods: {
        save(dismiss) {
            let outflow = parseFloat(this.outflow);
            let inflow = parseFloat(this.inflow);
            let record = {
                transfer:this.transfer,
                from: this.from,
                date: this.date,
                memo: this.memo,
                outflow: isNaN(outflow) ? 0 : outflow,
                inflow: isNaN(inflow) ? 0 : inflow,
                cleared: this.cleared,
                flag: this.flag,
                xRate: this.showExchange && this.xRate > 0 ? parseFloat(this.xRate) : undefined,
                tax: this.showTax && this.tax > 0 ? {
                    rate: parseFloat(this.tax / 100),
                    account: this.taxAccount.value
                } : undefined
            };
            if(this.transfer === true){
                record.to = this.to
            }
            else {
                if(this.payeeId)record.payeeId = this.payeeId
                else record.payee = this.payee
                record.categoryId = this.categoryId
            }
            console.log('new record',record)
            this.$store.dispatch("db/addTransaction", record);
            if (dismiss)
                this.$emit("dismiss");
            else {
                this.payee = ""; this.payeeId = null;
                this.category = ""; this.categoryId = null;
                this.memo = "";
                this.inflow = "";
                this.outflow = "";
                this.cleared = "U";
                this.flag = "";
                this.$refs.calendar.show()
            }
        },
        calendarKeyboard(e){
            // console.log('calendarKeyboard', e)
            let dt
            e.preventDefault()
            switch(e.key){
                
                case 'ArrowRight': {
                    dt = date.addToDate(this.date, {days:1})
                    break
                }
                case 'ArrowLeft': {
                    dt = date.addToDate(this.date, {days:-1})
                    break
                }
                case 'ArrowUp': {
                    e.preventDefault()
                    dt = date.addToDate(this.date, {days:-7})
                    break
                }
                case 'ArrowDown': {
                    e.preventDefault()
                    dt = date.addToDate(this.date, {days:7})
                    break
                }
            }
            if(e.key === 'Enter' || e.key === 'Tab'){
                this.$refs.calendar.hide()
                // this.showDatePick = false
                this.$refs.payeeInput.focus(); 
                this.$refs.payeeInput.select();
                return
            }
            this.date = date.formatDate(dt, 'YYYY-MM-DD')
        },
        dateFocusIn(e){
            // console.log('dateFocusIn',e)
            // this.$refs.calendar?.show()
            // this.showDatePick = true
        },
        dateFocusOut(e){
            // console.log('dateFocusOut',e)
        },
        payeeFocusIn(e){
            // console.log('payeeFocusIn', e)
            this.showPayee = true
        },
        setPayee(e) {
            // console.log('setPayee', e.target.value, e)
            // if(e.key == 'Tab' && e.shiftKey)return
            this.showPayee = false;
            e.preventDefault()
            if (this.filterPayees.length > 0) {
                let payee = this.filterPayees.find(item => item._id == this.payeeId)
                if(!payee)return
                if(payee.payee.includes('Transfer')){
                    let to = this.accounts.find(item => item.account === payee.payee.split(':')[1].trim())
                    if(to)this.to = to._id
                    this.payee = payee.payee
                    this.payeeId = null
                    this.categoryId = null; this.category = ''; this.transfer = true
                    // this.$refs.categoryInput.disable = true // causes bug
                    if(e.key && !e.shiftKey){
                        // move to next only if this is a keyboard event (solves bug that you cannot tab back)
                        this.$refs.memoInput.focus()
                        this.$refs.memoInput.select()   
                    }   
                }
                else {
                    this.payee = payee.payee;
                    this.payeeId = null
                    this.transfer = false
                    if (this.filterPayees[0].lastCategory) {
                        let category = this.findCategoryById(this.filterPayees[0].lastCategory._id)
                        this.category = category.category
                        this.categoryId = category._id                        
                    }
                    this.$refs.categoryInput.focus()
                    this.$refs.categoryInput.select()
                }
            }
            else this.payee = e.target.value;
        },
        
        payeeKeyUp(e){
            // console.log('payeeKeyUp',e)
            if(['ArrowUp', 'ArrowDown', 'Escape'].includes(e.key))e.preventDefault()
            const currIndex = this.filterPayees.findIndex(item => item._id == this.payeeId)
            let nextIndex = currIndex
            switch(e.key){
                case 'Enter': {
                    this.payeeId = this.filterPayees[nextIndex > -1 ? nextIndex : 0]?._id
                    return this.setPayee(e)
                }
                case 'ArrowUp': { nextIndex--; break; }
                case 'ArrowDown': { nextIndex++; break; }
                default: return
            }
            if(nextIndex > -1 && this.filterPayees[nextIndex]){
                this.payeeId = this.filterPayees[nextIndex]._id
            }
        },
        setCategory() {
            if (this.payee.includes("Transfer")) {
                this.showCategories = false;
                this.$refs.memoInput.focus()
                this.$refs.memoInput.select()
                return;
            }
            if (this.filterCategories.length > 0) {
                let first = this.filterCategories[0]
                if (first) {
                    this.category = `${first.category}`;
                    this.categoryId = first._id
                }
            }
            this.showCategories = false;
            this.$refs.memoInput.focus()
            this.$refs.memoInput.select()
        },
        setMemo(){
            this.$refs.outflowInput.focus()
            this.$refs.outflowInput.select()
        },

        setData(transData){
            // console.log('trans edit set data', transData)
            this.account = transData.account || this.lastAccount;
            this.date = transData.date || new Date().toISOString();
            this.payee = transData.payee || '';
            this.payeeId = transData.payeeId,
            this.category = transData.category || '';
            this.categoryId = transData.categoryId,
            this.memo = transData.memo || '';
            this.inflow = transData.inflow || 0;
            this.outflow = transData.outflow || 0;
            this.cleared = transData.cleared || 'U';
            this.flag = transData.flag || '';
        },
        setDate(value, reason, details){
            console.log(value, reason, details)
            this.$refs.calendar.hide()
            // this.showDatePick = false
            this.$refs.payeeInput.focus()
        },
        moveTo(target){
            let ref = this.$refs[target]
            ref.focus(); ref.select()
        },
        findCategoryById(id){
            let result = {}
            this.categories.map(mast => {
                let match = mast.subs.find(sub => sub._id === id)
                if(match){
                    result = match
                }
            })
            return result
        },
    },
    watch:{
        account(newAccount){
            let match = this.accounts.find(item => item.account === newAccount)
            // console.log('watch account', newAccount, match)
            if(match)this.from = match._id
        }
    },
    mounted() {
        if (this.transData) {
            this.setData(this.transData)
        }
        this.$refs.dateInput.focus()
        this.$refs.dateInput.select()
        this.$refs.calendar.show()
    },
    components: { ToolTip }
}
</script>

<style>

</style>