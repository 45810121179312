<template>
  <q-table
    :columns="Columns" :rows="data" row-key="_id" wrap-cells
    v-model:pagination="pagination"
    virtual-scroll :virtual-scroll-sticky-size-start="48"
    class="preview-sticky-virtscroll-table" dark
  >

  </q-table>
</template>

<script>
import { date } from 'quasar'
export default {
    name:'TransactionPreview',

    data(){
        return {
            pagination: { rowsPerPage: 0 }
        }
    },

    props:{
        data:Array
    },

    computed:{

        Columns(){
            return [
                {label:'Date', field:'date', align:'left', format:val=>this.formatDate(val)},
                {label:'Payee', field:'payee', align:'left'},
                {label:'Category', field:'category', align:'left'},
                {label:'Memo', field:'memo', align:'left'},
                {label:'Outflow', field:'outflow', format:val=>val===0?'':val},
                {label:'Inflow', field:'inflow', format:val=>val===0?'':val},
            ]
        },

    },

    methods: {
        formatDate(val){
            let d = new Date(val)
            return date.formatDate(d, "DD/MM/YYYY")
        }
    }
}
</script>

<style lang="sass">
.preview-sticky-virtscroll-table
  /* height or max-height is important */
  max-height: 480px

  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th /* bg color is important for th; just specify one */
    background-color: darkgrey

  thead tr th
    position: sticky
    z-index: 1
  /* this will be the loading indicator */
  thead tr:last-child th
    /* height of all previous header rows */
    top: 48px
  thead tr:first-child th
    top: 0

  /* prevent scrolling behind sticky top row on focus */
  tbody
    /* height of all previous header rows */
    scroll-margin-top: 48px
</style>