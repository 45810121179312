<template>
  <q-menu class="myClass shadow-10" fit anchor="bottom middle"  self="top middle" :offset="[0,32]"  >
    <div class="menuContent">
        <slot></slot>
    </div>
    
  </q-menu>
</template>

<script>
export default {
    name:'MenuTooltip',

    data(){
        return {
            show:true
        }
    },

    props:{
        target: String
    },

    computed:{
        
    },

    methods: {
        
    },

    mounted(){
        
    }
}
</script>

<style >
    /* .q-menu::after {
        content:'';
        width: 32px; height: 32px; 
        background-color: inherit;
        display: block;
        position: absolute; z-index: -1;
        left:50%; bottom:100%; margin-left: -16px; margin-bottom: -16px;
        box-shadow: inherit; overflow: visible;
        transform: rotate(45deg);
    }

    .q-menu {overflow: visible}

    .menuContent {
        background: inherit;
    } */
</style>