<template>
  <q-tooltip class="myClass shadow-10" no-parent-event fit anchor="bottom middle"  self="top middle" :offset="[0,32]"  >
    <h5>{{ custom }}</h5>
    <div class="menuContent">
        <slot></slot>
        
    </div>
    
  </q-tooltip>
</template>

<script>
export default {
    name:'ToolTip',

    data(){
        return {
            showTooltip:this.show,
            custom:this.value,
        }
    },

    props:{
        show: Boolean, value:String,
    },

    computed:{
        
    },

    methods: {
        toggle(e){console.log('toggle tooltip',e)}
    },

    mounted(){
        // this.showTooltip = this.show
        // console.log('set showTooltip', this.show)
        // console.log('value', this.value)
    }
}
</script>

<style >
    .q-tooltip::after {
        content:'';
        width: 32px; height: 32px; 
        background-color: inherit;
        display: block;
        position: absolute; z-index: -1;
        left:50%; bottom:100%; margin-left: -16px; margin-bottom: -16px;
        transform: rotate(45deg);
    }

    .q-tooltip {overflow: visible}

    .menuContent {
        background: inherit;
    }
</style>