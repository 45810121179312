import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import store from './store'
import router from './router'
import VueApexCharts from 'vue3-apexcharts'

createApp(App)
.use(Quasar, quasarUserOptions)
.use(store)
.use(router)
.use(VueApexCharts)
.mount('#app')
