<template>
  <div>
    <q-list separator>
        <q-item clickable v-for="item in accounts" :key="item.account" @click="setAccount(item.account)">
            <q-item-section>{{ item.account }}</q-item-section>
            <q-item-section side>
                {{ item.balance }}
                <q-icon name="done" color="primary" v-if="item.account === selected"/>
            </q-item-section>
        </q-item>
    </q-list>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
    name:"CategoryList",

    data(){
        return {
            account:''
        }
    },

    props: {
        selected: String
    },

    computed:{
        ...mapState({
            accounts: state => state.db.accounts
        }),
       
    },

    methods: {
        setAccount(account){
            this.$emit('setAccount',account)
        }
    }
}
</script>

<style>

</style>