<template>
  <div>
    <q-input v-model="payee" placeholder="Find or create payee..." autofocus>
        <template v-slot:prepend>
            <q-icon name="search" />
        </template>
    </q-input>
    <q-list separator>
        <q-item v-if="payee.length > 0 && payee !== filterPayees[0].payee" clickable @click="setPayee(payee)">
            <q-item-section avatar>
                <q-icon name="add_circle" color="positive"/>
            </q-item-section>
            <q-item-section class="text-positive">Create "{{ payee }}" payee</q-item-section>
        </q-item>
        <q-item clickable v-for="item in filterPayees" :key="item.payee" @click="setPayee(item.payee)">
            <q-item-section>{{ item.payee }}</q-item-section>
            <q-item-section side v-if="payee === item.payee && payee.length > 0">
                <q-icon name="done" color="primary"/>
            </q-item-section>
        </q-item>
    </q-list>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
    name:"PayeeList",

    data(){
        return {
            payee:''
        }
    },

    props: {
        account: String
    },

    computed:{
        ...mapState({
            payees: state => state.db.payees
        }),
        ...mapGetters({
            getPayees: 'db/getPayees',
        }),

        filterPayees(){
            return this.getPayees(this.account).filter(item => {
                return item.payee.toLowerCase().includes(this.payee.toLowerCase())
            }).slice(0,20)
        }
    },

    methods: {
        setPayee(payee){
            this.payee = payee
            this.$emit('setPayee',payee)
        }
    }
}
</script>

<style>

</style>