<template>
  <div class="q-pa-md">
    <div class="text-h4">Categories</div>
    <q-toolbar class="q-gutter-lg">
        <q-btn label="Select All" color="primary" flat @click="selectAllNone(true)"/>
        <q-btn label="Select None" color="primary" flat @click="selectAllNone(false)"/>
    </q-toolbar>
    <q-list separator>
        <q-item v-for="item in accounts" :key="item._id" >
            <q-item-section avatar>
                <q-checkbox v-model="item.selected" @click="selectItem(item.selected, item._id)"/>
            </q-item-section>
            <q-item-section>{{ item.account }}</q-item-section>
        </q-item>  
    </q-list>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    name:"AccountSelect",

    data(){
        return {
            selectedAcc:[], accounts:[]
        }
    },

    props: {
        selected: Array
    },

    computed:{
        ...mapState({
            accountState: state => state.db.accounts
        }),
        
        selectedAccounts(){
            let ids = []
            this.accounts.map(item => {
                if(item.selected === true)ids.push(item._id)
            })
            return ids
        }
    },

    methods: {
        selectItem(value, id){
            let account = this.accounts.find(item => item._id === id)
            account.selected = value
            // this.$emit('update:selected', this.selectedAccounts)
        },
        
        selectAllNone(all){
            this.accounts.map(item => item.selected = all)
            // this.$emit('update:selected', this.selectedAccounts)
        },
    },

    mounted(){
        this.accounts = this.accountState.map(item => {
            item.selected = this.selected.length > 0 ? this.selected.includes(item._id) : true
            return item
        })
    },

    beforeUnmount(){
        this.$emit('update:selected', this.selectedAccounts)
    }
}
</script>

<style>

</style>